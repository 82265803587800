import useMainStore from '@/store/main.store';
import {formatServerErrorResponse} from '@/helpers/jwtHelper';
import jwtInterceptor from '@/helpers/http';
import AddKnowledgeCheckEvaluation from "@/models/student/evaluation/AddKnowledgeCheckEvaluation";
import IAssignmentForStudentView from '@/models/courses/StudentAssignment/IAssignmentForStudentView';
import CompletedCompetencyEvaluation from "@/models/courses/competencies/CompletedCompetencyEvaluation";
import AddNonCeEvaluation from "@/models/student/evaluation/AddNonCeEvaluation";
import ICourseMenuContent from '@/models/courses/Course/ICourseMenuContent';
import AddCeEvaluation from "@/models/student/evaluation/AddCeEvaluation";
import ILessonAssignment from '@/models/courses/Lesson/ILessonAssignment';
import ISessionResume from '@/models/student/scorm/ISessionResume';
import CourseFormat from '@/models/courses/Course/CourseFormat';
import ISessionInfo from '@/models/student/scorm/ISessionInfo';
import { useSessionStorage } from '@vueuse/core';
import {computed, ComputedRef, Ref} from 'vue';
import {defineStore} from 'pinia';
import {AxiosError, AxiosResponse} from 'axios';

interface IAssignmentStore {
    getAllAssignments: ComputedRef<IAssignmentForStudentView[]>,
    getIncompleteCourseAssignments: ComputedRef<IAssignmentForStudentView[]>,
    getCompletedCourseAssignments: ComputedRef<IAssignmentForStudentView[]>,
    getIncompleteSkillAssignments: ComputedRef<IAssignmentForStudentView[]>,
    getCompletedSkillAssignments: ComputedRef<IAssignmentForStudentView[]>,

    getCourseAssignmentByCourseId(courseId: number): IAssignmentForStudentView | undefined,
    getLessonAssignmentByLessonId(lessonId: number): ILessonAssignment,
    getMyAssignments(): Promise<IAssignmentForStudentView[]>,
    getMyLessonAssignments(studentId: number, courseId: number): Promise<ILessonAssignment[]>,
    getCourseAssignmentStatus(studentId: number, courseId: number): Promise<string>,
    getCourseMenuInfo(courseId: number): Promise<ICourseMenuContent | void>,
    hasCompetencyBeenEvaluated(studentId: number, courseId: number): Promise<boolean>,
    getMostRecentEvaluation(studentId: number, courseId: number): Promise<CompletedCompetencyEvaluation>,
    startSession(studentId: number, courseId: number, lessonId: number): Promise<string>,
    getResumeSessionData(studentId: number, courseId: number, lessonId: number): Promise<ISessionResume>,
    saveSession(session: ISessionInfo): Promise<void>,
    exitAssignment(sessionId: string): Promise<void>,
    submitNonCeEvaluation(evaluationMdl: AddNonCeEvaluation): Promise<void>,
    submitCeEvaluation(evaluationMdl: AddCeEvaluation): Promise<void>,
    submitKnowledgeCheckEvaluation(evaluationMdl: AddKnowledgeCheckEvaluation): Promise<void>,
    $reset(): void,
}

const useAssignmentStore = defineStore('assignmentStore', (): IAssignmentStore => {
    const mainStore = useMainStore();

    const courseAssignments: Ref<IAssignmentForStudentView[]> = useSessionStorage('assignmentStore_courseAssignments', [] as IAssignmentForStudentView[]);
    const lessonAssignments: Ref<ILessonAssignment[]> = useSessionStorage('assignmentStore_lessonAssignments', [] as ILessonAssignment[]);

    const getAllAssignments: ComputedRef<IAssignmentForStudentView[]> = computed<IAssignmentForStudentView[]>(() => courseAssignments.value);
    const getIncompleteCourseAssignments: ComputedRef<IAssignmentForStudentView[]> = computed<IAssignmentForStudentView[]>(() => courseAssignments.value.filter(sa => sa.courseFormatId === CourseFormat.Course && sa.status !== 'Complete'));
    const getCompletedCourseAssignments: ComputedRef<IAssignmentForStudentView[]> = computed<IAssignmentForStudentView[]>(() => courseAssignments.value.filter(sa => sa.courseFormatId === CourseFormat.Course && sa.status === 'Complete'));
    const getIncompleteSkillAssignments: ComputedRef<IAssignmentForStudentView[]> = computed<IAssignmentForStudentView[]>(() => courseAssignments.value.filter(sa => sa.courseFormatId !== CourseFormat.Course && sa.status !== 'Complete'));
    const getCompletedSkillAssignments: ComputedRef<IAssignmentForStudentView[]> = computed<IAssignmentForStudentView[]>(() => courseAssignments.value.filter(sa => sa.courseFormatId !== CourseFormat.Course && sa.status === 'Complete'));

    function getCourseAssignmentByCourseId(courseId: number): IAssignmentForStudentView | undefined {
        return courseAssignments.value.find((ca: IAssignmentForStudentView) => ca.courseId === courseId);
    }

    function getLessonAssignmentByLessonId(lessonId: number): ILessonAssignment {
        const lesson = lessonAssignments.value.find((la: ILessonAssignment) => la.lessonId === lessonId);

        if (lesson === undefined)
            throw new Error('Lesson not found');

        return lesson
    }

    async function getMyAssignments(): Promise<IAssignmentForStudentView[]> {
        const actionName = 'getMyAssignments';
        mainStore.startTask(actionName);

        try {
            const getMyAssignmentsResponse: AxiosResponse<IAssignmentForStudentView[]> = await jwtInterceptor.get('api/student/assignment/courses');

            courseAssignments.value = getMyAssignmentsResponse.data;
            return courseAssignments.value;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Assignments for Student', error);

            mainStore.setErrorMsg(errorMsg);
            courseAssignments.value = [];
            return courseAssignments.value;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getMyLessonAssignments(studentId: number, courseId: number): Promise<ILessonAssignment[]> {
        const actionName = 'getMyLessonAssignments';
        mainStore.startTask(actionName);

        try {
            const getMyLessonsResponse: AxiosResponse<ILessonAssignment[]> = await jwtInterceptor.get('api/student/assignment/lessons', { params: { studentId, courseId } });

            lessonAssignments.value = getMyLessonsResponse.data;
            return lessonAssignments.value;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Assignments for Student', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCourseAssignmentStatus(studentId: number, courseId: number): Promise<string> {
        const actionName = 'getCourseAssignmentStatus';
        mainStore.startTask(actionName);

        try {
            const getCourseAssignmentStatusResponse: AxiosResponse<string> = await jwtInterceptor.get('api/student/assignment/get-course-assignment-status', { params: { studentId, courseId } });

            return  getCourseAssignmentStatusResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Assignments for Student', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCourseMenuInfo(courseId: number): Promise<ICourseMenuContent | void> {
        const actionName = 'getCourseMenuInfo';
        mainStore.startTask(actionName);

        try {
            const getCourseMenuInfoResponse: AxiosResponse<ICourseMenuContent> = await jwtInterceptor.get('api/student/assignment/course-menu-content', { params: { courseId } });

            return getCourseMenuInfoResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Course Menu Content', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function hasCompetencyBeenEvaluated(studentId: number, courseId: number): Promise<boolean> {
        const actionName = 'hasCompetencyBeenEvaluated';
        mainStore.startTask(actionName);

        try {
            const hasCompetencyBeenEvaluatedResponse: AxiosResponse<boolean> = await jwtInterceptor.get('api/student/assignment/has-evaluation-result', { params: { studentId, courseId } });

            return hasCompetencyBeenEvaluatedResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Competency Evaluation', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getMostRecentEvaluation(studentId: number, courseId: number): Promise<CompletedCompetencyEvaluation> {
        const actionName = 'getMostRecentEvaluation';
        mainStore.startTask(actionName);

        try {
            const getMostRecentEvaluationResponse: AxiosResponse<CompletedCompetencyEvaluation> = await jwtInterceptor.get('api/student/assignment/most-recent-evaluation', { params: { studentId, courseId } });

            return getMostRecentEvaluationResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Most Recent Evaluation', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }


    async function startSession(studentId: number, courseId: number, lessonId: number): Promise<string> {
        const actionName = 'startSession';
        mainStore.startTask(actionName);

        try {
            const startSessionResponse: AxiosResponse<string> = await jwtInterceptor.get('api/student/assignment/start-session', { params: { studentId, courseId, lessonId } });

            return startSessionResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to generate Session Id', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getResumeSessionData(studentId: number, courseId: number, lessonId: number): Promise<ISessionResume> {
        const actionName = 'getResumeSessionData';
        mainStore.startTask(actionName);

        try {
            const getResumeSessionDataResponse: AxiosResponse<ISessionResume> = await jwtInterceptor.get('api/student/assignment/resume-session', { params: { studentId, courseId, lessonId } })

            return getResumeSessionDataResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to generate Session Id', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function saveSession(session: ISessionInfo): Promise<void> {
        const actionName = 'saveSession';
        await waitUntilActionIsComplete(actionName);
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/student/assignment/save-session', session);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to save session data', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function exitAssignment(sessionId: string): Promise<void> {
        const actionName = 'exitAssignment';
        await waitUntilActionIsComplete('saveSession');
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/student/assignment/exit-assignment',
                sessionId,
                { headers: { 'Content-Type': 'application/json' } }
            )
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to save exit and save lesson data', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function submitNonCeEvaluation(evaluationMdl: AddNonCeEvaluation): Promise<void> {
        const actionName = 'submitNonCeEvaluation';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/student/assignment/submit-non-ce-evaluation', evaluationMdl);

        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to save non CE evaluation data', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function submitCeEvaluation(evaluationMdl: AddCeEvaluation): Promise<void> {
        const actionName = 'submitCeEvaluation';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/student/assignment/submit-ce-evaluation', evaluationMdl);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to save CE Evaluation data', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function submitKnowledgeCheckEvaluation(evaluationMdl: AddKnowledgeCheckEvaluation): Promise<void> {
        const actionName = 'submitKnowledgeCheckEvaluation';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/student/assignment/submit-knowledge-check-evaluation', evaluationMdl);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to save Knowledge Check Evaluation data', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function waitUntilActionIsComplete(actionName: string) {
        await new Promise(f => setTimeout(f, 250));
        let isTaskActive = mainStore.getRunningTasks.some(task => task.action === actionName);

        while (isTaskActive) {
            await new Promise(f => setTimeout(f, 500));
            isTaskActive = mainStore.getRunningTasks.some(task => task.action === actionName);
        }
    }

    function $reset() {
        courseAssignments.value = [];
        lessonAssignments.value = [];
    }


    return {
        getAllAssignments,
        getIncompleteCourseAssignments,
        getCompletedCourseAssignments,
        getIncompleteSkillAssignments,
        getCompletedSkillAssignments,

        getCourseAssignmentByCourseId,
        getLessonAssignmentByLessonId,
        getMyAssignments,
        getMyLessonAssignments,
        getCourseAssignmentStatus,
        getCourseMenuInfo,
        hasCompetencyBeenEvaluated,
        getMostRecentEvaluation,
        startSession,
        getResumeSessionData,
        saveSession,
        exitAssignment,
        submitNonCeEvaluation,
        submitCeEvaluation,
        submitKnowledgeCheckEvaluation,
        $reset
    }
});

export default useAssignmentStore;
