import jwtInterceptor from '@/helpers/http';
import IGridFilter from '@/models/search/IGridFilter';
import IDivisionSearchResult from '@/models/search/Organizations/IDivisionSearchResult';
import IFacilitySearchResult from '@/models/search/Organizations/IFacilitySearchResult';
import IRegionSearchResult from '@/models/search/Organizations/IRegionSearchResult';
import ICorporation from '@/models/user/OrganizationLevel/Corporation/ICorporation';
import ISimpleCorporation from '@/models/user/OrganizationLevel/Corporation/ISimpleCorporation';
import IUpdateCorporation from '@/models/user/OrganizationLevel/Corporation/IUpdateCorporation';
import IDepartment from '@/models/user/OrganizationLevel/Department/IDepartment';
import IDivision from '@/models/user/OrganizationLevel/Division/IDivision';
import ISimpleDivision from '@/models/user/OrganizationLevel/Division/ISimpleDivision';
import IUpdateDivision from '@/models/user/OrganizationLevel/Division/IUpdateDivision';
import IFacility from '@/models/user/OrganizationLevel/Facility/IFacility';
import IOrganizationHierarchy from '@/models/user/OrganizationLevel/IOrganizationHierarchy';
import IRegion from '@/models/user/OrganizationLevel/Region/IRegion';
import ISimpleRegion from '@/models/user/OrganizationLevel/Region/ISimpleRegion';
import IUpdateRegion from '@/models/user/OrganizationLevel/Region/IUpdateRegion';
import IUpdateFacility from '@/models/user/OrganizationLevel/Facility/IUpdateFacility';
import IDepartmentSearchResult from '@/models/search/Organizations/IDepartmentSearchResult';
import IUpdateDepartment from '@/models/user/OrganizationLevel/Department/IUpdateDepartment';
import IOrgWideDeptSearchIds from '@/models/search/Organizations/IOrgWideDeptSearchIds';
import ISimpleStudent from '@/models/user/ISimpleStudent';
import { formatServerErrorResponse } from '@/helpers/jwtHelper';
import { AxiosError, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import useMainStore from './main.store';
import {ref, Ref} from 'vue';

export interface IOrganizationStore {

    getAllCorporations(): Promise<ISimpleCorporation[]>,
    getCorporation(corporationId: number): Promise<ICorporation>,
    updateCorporation(corporation: IUpdateCorporation): Promise<void>,
    searchRegions(filter: IGridFilter): Promise<IRegionSearchResult>,
    getRegionsByCorporation(corporationId: number): Promise<ISimpleRegion[]>,
    getRegion(regionId: number): Promise<IRegion>
    updateRegion(region: IUpdateRegion): Promise<void>,
    searchDivisions(filter: IGridFilter): Promise<IDivisionSearchResult>,
    getDivisionsByRegion(regionId: number): Promise<ISimpleDivision[]>,
    getDivision(divisionId: number): Promise<IDivision>,
    updateDivision(division: IUpdateDivision): Promise<void>,
    searchFacilities(filter: IGridFilter): Promise<IFacilitySearchResult>,
    getAllFacilities(identityId: string): Promise<IFacility[]>,
    getFacilitiesByDivision(divisionId: number): Promise<IFacility[]>,
    getFacility(facilityId: number): Promise<IFacility>,
    updateFacility(facility: IUpdateFacility): Promise<void>,
    getOrganizationHierarchyByFacility(facilityId: number): Promise<IOrganizationHierarchy>,
    searchDepartments(filter: IGridFilter): Promise<IDepartmentSearchResult>,
    getDepartmentsByFacility(facilityId: number): Promise<IDepartment[]>,
    getDepartment(departmentId: number): Promise<IDepartment>,
    updateDepartment(department: IUpdateDepartment): Promise<void>,
    getStudentsByDepartment(departmentId: number, includeInactive: boolean): Promise<ISimpleStudent[]>,
    organizationForMatchingDepts(orgIds: IOrgWideDeptSearchIds): Promise<string[]>,
    getAllDepartmentNames(): Promise<string[]>,
}

const useOrganizationStore = defineStore('organizationStore', (): IOrganizationStore => {
    const mainStore = useMainStore();

    const corporations: Ref<ISimpleCorporation[]> = ref<ISimpleCorporation[]>([]);

    async function getAllCorporations(): Promise<ISimpleCorporation[]> {
        const isCorporationsNullOrEmpty = !corporations.value || !corporations.value.length;

        if (!isCorporationsNullOrEmpty) {
            return corporations.value;
        }

        const actionName = 'GetAllCorporations';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleCorporation[]> = await jwtInterceptor.get('api/organization/all-corporations');

            corporations.value = response.data;

            return corporations.value;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Corporations', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCorporation(corporationId: number): Promise<ICorporation> {
        const actionName = 'getCorporation';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ICorporation> = await jwtInterceptor.get('api/organization/corporation', { params: { corporationId }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Corporations', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCorporation(corporation: IUpdateCorporation): Promise<void> {
        const actionName = 'updateCorporation';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/organization/update-corporation', corporation);
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Corporation', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchRegions(filter: IGridFilter): Promise<IRegionSearchResult> {
        const actionName = 'searchRegions';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);
            const response: AxiosResponse<IRegionSearchResult> = await jwtInterceptor.get('api/organization/search-regions', { params: { filter: btoa(filterJson) }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return Region search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getRegionsByCorporation(corporationId: number): Promise<ISimpleRegion[]> {
        const actionName = 'getRegionsByCorporation';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleRegion[]> = await jwtInterceptor.get('api/organization/regions-by-corporation', { params: { corporationId } });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Regions by Corporation', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getRegion(regionId: number): Promise<IRegion> {
        const actionName = 'getRegion';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IRegion> = await jwtInterceptor.get('api/organization/region', { params: { regionId }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Region', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateRegion(region: IUpdateRegion): Promise<void> {
        const actionName = 'updateRegion';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/organization/update-region', region);
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Region', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchDivisions(filter: IGridFilter): Promise<IDivisionSearchResult> {
        const actionName = 'searchDivisions';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);

            const response: AxiosResponse<IDivisionSearchResult> = await jwtInterceptor.get('api/organization/search-divisions', { params: { filter: btoa(filterJson) }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return Division search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getDivisionsByRegion(regionId: number): Promise<ISimpleDivision[]> {
        const actionName = 'GetDivisions';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleDivision[]> = await jwtInterceptor.get('api/organization/divisions-by-region', { params: { regionId } });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Divisions by Region', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getDivision(divisionId: number): Promise<IDivision> {
        const actionName = 'getDivision';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IDivision> = await jwtInterceptor.get('api/organization/division', { params: { divisionId }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Division', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateDivision(division: IUpdateDivision): Promise<void> {
        const actionName = 'updateDivision';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/organization/update-division', division);
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Division', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchFacilities(filter: IGridFilter): Promise<IFacilitySearchResult> {
        const actionName = 'searchFacilities';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);

            const response: AxiosResponse<IFacilitySearchResult> = await jwtInterceptor.get('api/organization/search-facilities', {params: {filter: btoa(filterJson)}});

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return Facility search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw error;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAllFacilities(identityId: string): Promise<IFacility[]> {
        const actionName = 'getAllFacilities';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IFacility[]> = await jwtInterceptor.get('api/organization/all-facilities', {
                params: { identityId }
            });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch all facilities', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getFacilitiesByDivision(divisionId: number): Promise<IFacility[]> {
        const actionName = 'getFacilitiesByDivision';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IFacility[]> = await jwtInterceptor.get('api/organization/facilities-by-division', { params: { divisionId } });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Facilities by Division', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getFacility(facilityId: number): Promise<IFacility> {
        const actionName = 'getFacility';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IFacility> = await jwtInterceptor.get('api/organization/facility', { params: { facilityId }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Facility', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateFacility(facility: IUpdateFacility): Promise<void> {
        const actionName = 'updateFacility';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/organization/update-facility', facility);
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Facility', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getOrganizationHierarchyByFacility(facilityId: number): Promise<IOrganizationHierarchy> {
        const actionName = 'getDepartmentsByFacility';
        mainStore.startTask(actionName);

        try {
            const hierarchyResponse: AxiosResponse<IOrganizationHierarchy> = await jwtInterceptor.get('api/organization/hierarchy-by-facility', { params: { facilityId } })

            return hierarchyResponse.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Departments by Facility', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchDepartments(filter: IGridFilter): Promise<IDepartmentSearchResult> {
        const actionName = 'searchDepartments';
        mainStore.startTask(actionName);

        try {
            const filterJson = JSON.stringify(filter);

            const response: AxiosResponse<IDepartmentSearchResult> = await jwtInterceptor.get('api/organization/search-departments', {params: {filter: btoa(filterJson)}});

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return Department search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getDepartmentsByFacility(facilityId: number): Promise<IDepartment[]> {
        const actionName = 'getDepartmentsByFacility';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IDepartment[]> = await jwtInterceptor.get('api/organization/departments-by-facility', { params: { facilityId } });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Departments by Facility', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getDepartment(departmentId: number): Promise<IDepartment> {
        const actionName = 'getDepartment';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<IDepartment> = await jwtInterceptor.get('api/organization/department', { params: { departmentId }} );

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Department', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateDepartment(department: IUpdateDepartment): Promise<void> {
        const actionName = 'updateDepartment';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/organization/update-department', department);
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Department', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getStudentsByDepartment(departmentId: number, includeInactive: boolean): Promise<ISimpleStudent[]> {
        const actionName = 'getStudentsByDepartment';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<ISimpleStudent[]> = await jwtInterceptor.get('api/organization/students-by-department', { params: { departmentId, includeInactive } });

            return response.data;
        }
        catch(error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Students by Department', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function organizationForMatchingDepts(orgIds: IOrgWideDeptSearchIds): Promise<string[]> {
        const actionName = 'organizationForMatchingDepts';
        mainStore.startTask(actionName);

        const corporationId = orgIds.corporationId;
        const regionId = orgIds.regionId;
        const divisionId = orgIds.divisionId;

        try {
            const response: AxiosResponse<string[]> = await jwtInterceptor.get('api/organization/organization-depts', {
                params: { corporationId, regionId, divisionId }
            });

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to find departments given organization', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAllDepartmentNames(): Promise<string[]> {
        const actionName = 'getAllDepartmentNames';
        mainStore.startTask(actionName);

        try {
            const response: AxiosResponse<string[]> = await jwtInterceptor.get('api/organization/all-departments');

            return response.data;
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable fetch all department names', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    return {
        getAllCorporations,
        getCorporation,
        updateCorporation,

        searchRegions,
        getRegionsByCorporation,
        getRegion,
        updateRegion,

        searchDivisions,
        getDivisionsByRegion,
        getDivision,
        updateDivision,

        searchFacilities,
        getAllFacilities,
        getFacilitiesByDivision,
        getFacility,
        updateFacility,
        getOrganizationHierarchyByFacility,

        searchDepartments,
        getDepartmentsByFacility,
        getDepartment,
        updateDepartment,
        getStudentsByDepartment,
        organizationForMatchingDepts,
        getAllDepartmentNames,
    }
});

export default useOrganizationStore;
