<script setup lang="ts">
import ISimpleCourse from '@/models/courses/Course/ISimpleCourse';
import IAnnualCourseAssignment from '@/models/courses/StudentAssignment/Annual/IAnnualCourseAssignment';
import useCourseAssignmentStore from '@/store/courseAssignment.store';
import useMainStore from '@/store/main.store';
import { computed, onMounted, ref, watch } from 'vue';
import router from '@/router';

// eslint-disable-next-line no-undef
const props = defineProps<{
    srcAnnualAssignment?: IAnnualCourseAssignment
}>();

const mainStore = useMainStore();
const courseAssignmentStore = useCourseAssignmentStore();

const showDeleteModal = ref<boolean>(false);

const facilityName = ref<string>('');
const departmentName = ref<string>('');
const createdByName = ref<string>('');
const disabledByName = ref<string>('');
const createdOnDate = ref<string>('');
const disabledOnDate = ref<string>('');
const assignByDepartment = ref<boolean>(false);
const course = ref<ISimpleCourse>();
const daysToTarget = ref<number>(0);
const daysToDrop = ref<number>(0);
const startDate = ref<string>();
const isDisabled = ref<boolean>(false);
const dropBeforeReassign = ref<boolean>(false);
const continuousProcess = ref<boolean>(false);
const forceTargetDate = ref<boolean>(false);
const omitNewHire = ref<boolean>(false);

const annualAssignment = computed(() => props.srcAnnualAssignment ?? {} as IAnnualCourseAssignment);
const isLoading = computed<boolean>(() => mainStore.isBusy);

onMounted(() => initializeValues());

watch(annualAssignment.value, (() => initializeValues()));

const initializeValues = () => {
    if (!annualAssignment.value) return;

    facilityName.value = annualAssignment.value.facility?.name ?? '';
    departmentName.value = annualAssignment.value.departmentName ?? '';
    assignByDepartment.value = annualAssignment.value.assignByDepartment ?? false;
    course.value = annualAssignment.value.course;
    daysToTarget.value = annualAssignment.value.daysToTarget ?? 0;
    daysToDrop.value = annualAssignment.value.daysToDrop ?? 0;
    startDate.value = !annualAssignment.value.startDate ? '' : new Date(annualAssignment.value.startDate).toLocaleString();
    isDisabled.value = annualAssignment.value.isDisabled;
    dropBeforeReassign .value = annualAssignment.value.dropBeforeReassign;
    continuousProcess .value = annualAssignment.value.continuousProcess;
    forceTargetDate .value = annualAssignment.value.forceTargetDate;
    omitNewHire .value = annualAssignment.value.omitNewHire;
    createdByName.value = !annualAssignment.value.createdByAdmin ?  '' : `${ annualAssignment.value.createdByAdmin.firstName } ${ annualAssignment.value.createdByAdmin.lastName }`;
    disabledByName.value = !annualAssignment.value.disabledByAdmin ?  '' : `${ annualAssignment.value.disabledByAdmin.firstName } ${ annualAssignment.value.disabledByAdmin.lastName }`;
    createdOnDate.value = new Date(annualAssignment.value.createdOn).toLocaleDateString();
    disabledOnDate.value = !annualAssignment.value.disabledDate ? '' : new Date(annualAssignment.value.disabledDate).toLocaleDateString();
};
    
const deleteAssignment = (async() => {
    const annualCourseAssignmentId = annualAssignment.value?.annualCourseAssignmentId;

    if (!annualCourseAssignmentId)
        return mainStore.setErrorMsg('Annual Assignment Id not found')

    try {
        await courseAssignmentStore.deleteAnnualAssignment(annualCourseAssignmentId);

        showDeleteModal.value = false;

        setTimeout(() => router.push('/automated-course-assignment/annual'), 2500);
    } catch (e) {
        showDeleteModal.value = false;
        window.scrollTo(0, 0);
    }
});

</script>

<template>
    <div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Facility: </span>
            <span> {{ facilityName }} </span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Assign By Department</span>
            <span> {{ assignByDepartment ? "Yes" : "No" }} </span>
        </div>
        
        <div v-show="assignByDepartment">
            <div class="flex flex-row mt-3" >
                <span class="mr-2">Department: </span>
                <span> {{ departmentName }} </span>
            </div>
        </div>
                
        <div class="flex flex-row mt-3">
            <span class="mr-2">Course: </span>
            <span> {{ course?.name }} </span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Assign Start Date</span>
            <span> {{ startDate }} </span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Days to Target: </span>
            <span> {{ daysToTarget }} Days</span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Days to Drop: </span>
            <span> {{ daysToDrop }} Days</span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Drop Before Reassign: </span>
            <span> {{ dropBeforeReassign ? "Yes" : "No" }} </span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Continuous Process: </span>
            <span> {{ continuousProcess ? "Yes" : "No" }} </span>
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Force Target Date: </span>
            <span> {{ forceTargetDate ? "Yes" : "No" }} </span>
        </div>
      
        <div class="flex flex-row mt-3">
            <span class="mr-2">Omit New Hire: </span>
            <span> {{ omitNewHire ? "Yes" : "No" }} </span>
        </div>
      
        <div class="flex flex-row mt-3">
            <span class="mr-2">Disabled: </span>
            <span> {{ isDisabled ? "Yes" : "No" }} </span>
        </div>
        
        <div class="flex flex-row justify-content-between md:justify-content-end mb-4 mt-6">
            <Button 
                label="Delete" 
                icon="pi pi-times" 
                iconPos="right"
                severity="danger" 
                @click="showDeleteModal = true"
                :loading="isLoading" 
            />
        </div>

        <div class="flex flex-row mt-3">
            <span class="mr-2">Created By: </span>
            <span> {{ createdByName }} </span>
            <span class="mx-2"> on </span>
            <span> {{ createdOnDate }} </span>
        </div>

        <div v-show="false">
            <div class="flex flex-row mt-3">
                <span class="mr-2">Modified by: </span>
                <span> {{  }} </span>
                <span class="mx-2">on</span>
                <span> {{  }} </span>
            </div>
        </div>

        <div v-show="isDisabled">    
            <div class="flex flex-row mt-3">
                <span class="mr-2">Disabled By: </span>
                <span> {{ disabledByName }} </span>
                <span class="mx-2">on</span>
                <span> {{ disabledOnDate }} </span>
            </div>
        </div>

        <Dialog
            v-model:visible="showDeleteModal"
            modal
            header="Are you sure?"
            :draggable="false"
        >
            <div class="mt-3">
                <Button 
                    label="No" 
                    icon="pi pi-times" 
                    class="mr-3"
                    text 
                    autofocus
                    @click="showDeleteModal = false" 
                />
                <Button 
                    label="Yes" 
                    icon="pi pi-check" 
                    @click="deleteAssignment" 
                />
            </div>
        </Dialog>

    </div>
</template>
