<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import useCourseOrgAccessSearch from '@/composition-api/Search/useCourseOrgAccessSearch';
import ICourseGroupAccess from '@/models/courses/Course/ICourseGroupAccess';
import IModifyRestrictedCourse from '@/models/courses/Course/IModifyRestrictedCourse';
import IOrgCourseAccess from '@/models/courses/Course/IOrgCourseAccess';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import * as zod from 'zod';

const mainStore = useMainStore();
const courseStore = useCourseStore();
const {
    courseIdSearch,
    corporations,
    corporationId,
    isCorporationLoading,

    getRegionByCorp,
    regions,
    regionId,
    isRegionLoading,
    isRegionDisabled,

    getDivisionsByRegion,
    divisions,
    divisionId,
    isDivisionLoading,
    isDivisionDisabled,

    getFacilitiesByDivision,
    facilities,
    facilityId,
    isFacilityLoading,
    isFacilityDisabled,

    courseOrgAccessFilters,
    courseOrgAccessFromSearch,
    totalRecords,
    onPage,
    executeSearch
} = useCourseOrgAccessSearch();

courseStore.$onAction(({ after, name }) => {
    after(() => {
        if (name === 'getCourse'){
            initializeValues();
        }
    })
});

const { handleSubmit, resetForm } = useForm({
    initialValues: {
        allowAccessToSkilledNursing: false,
        allowAccessToeCompetency: false,
        allowAccessToAssistedLiving: false,
    },
    validationSchema: toTypedSchema(
        zod.object({
            allowAccessToSkilledNursing: zod.boolean(),
            allowAccessToeCompetency: zod.boolean(),
            allowAccessToAssistedLiving: zod.boolean(),
        })
    )
});

const { value: allowAccessToSkilledNursing } = useField<boolean>('allowAccessToSkilledNursing');
const { value: allowAccessToeCompetency } = useField<boolean>('allowAccessToeCompetency');
const { value: allowAccessToAssistedLiving } = useField<boolean>('allowAccessToAssistedLiving');

const noOrgSelected = computed<boolean>(() => !corporationId.value)
const isLoading = computed<boolean>(() => mainStore.isBusy);

onMounted(async() => initializeValues());

function initializeValues() {
    const course = courseStore.getActiveCourse;

    if (!course?.courseId)
        return;

    courseIdSearch.value = course.courseId;

    resetForm({ values: {
            allowAccessToSkilledNursing: course.allowAccessToSkilledNursing ?? false,
            allowAccessToAssistedLiving: course.allowAccessToAssistedLiving ?? false,
            allowAccessToeCompetency: course.allowAccessToeCompetency ?? false
        }
    })
}

const onSubmit = handleSubmit(async (values: any) => {
    if (!courseIdSearch.value)
        return mainStore.setErrorMsg('Course Id not found.')

    const {
        allowAccessToSkilledNursing,
        allowAccessToeCompetency,
        allowAccessToAssistedLiving,
	} = values

    const courseGroupAccess: ICourseGroupAccess = {
        courseId: courseIdSearch.value,
        allowAccessToSkilledNursing,
        allowAccessToeCompetency,
        allowAccessToAssistedLiving,
    };

    await courseStore.updateCourseGroupAccess(courseGroupAccess);
});

async function revokeCourseAccess(orgAccess: IOrgCourseAccess) {
    if (!courseIdSearch.value)
        return mainStore.setErrorMsg('Course Id not found.')

    const editAccessMdl: IModifyRestrictedCourse = {
        courseId: courseIdSearch.value,
        organizationLevel: orgAccess.organizationLevel,
        organizationId: orgAccess.organizationId,
    }

    try {
        await courseStore.revokeCourseAccess(editAccessMdl);

        const updatedIndex = courseOrgAccessFromSearch.value
            .findIndex(i =>
                i.organizationId === orgAccess.organizationId &&
                i.organizationLevel === orgAccess.organizationLevel)

        if (updatedIndex == -1) {
            mainStore.setErrorMsg('Access Removed. Unable to find organization. Please refresh page.');
            return;
        }

        courseOrgAccessFromSearch.value.filter(i =>
            i.organizationId === orgAccess.organizationId &&
            i.organizationLevel === orgAccess.organizationLevel)[0].isAllowedAccessToCourse = false;
    } catch(e) {
        console.error('Error revoking course access', e);
    }
}
 
async function enableCourseAccess(orgAccess: IOrgCourseAccess) {
    if (!courseIdSearch.value)
        return mainStore.setErrorMsg('Course Id not found.')

    const editAccessMdl: IModifyRestrictedCourse = {
        courseId: courseIdSearch.value,
        organizationLevel: orgAccess.organizationLevel,
        organizationId: orgAccess.organizationId,
    }

    try {
        await courseStore.enableCourseAccess(editAccessMdl)

        const updatedIndex = courseOrgAccessFromSearch.value
            .findIndex(i => 
            i.organizationId === orgAccess.organizationId && 
            i.organizationLevel === orgAccess.organizationLevel)

        if (!updatedIndex) 
            return mainStore.setErrorMsg('Access Enabled. Unable to find organization. Please refresh page.');

        courseOrgAccessFromSearch.value.filter(i => 
            i.organizationId === orgAccess.organizationId && 
            i.organizationLevel === orgAccess.organizationLevel)[0]
            .isAllowedAccessToCourse = true;
    }
    catch {
        console.error('Error enabling course access');
    }  
}

</script>

<template>
    <div class="border-round-lg p-3" style="background-color: var(--hca-form-bg);">

        <TabView>
            <TabPanel header="Assign By Organization">
                <div class="flex flex-column">
                    <div class="flex flex-column mt-5 gap-5">

                        <span class="p-float-label w-full">
                            <Dropdown
                                v-model="corporationId"
                                filter
                                inputId="corporationSearch"
                                class="w-full"
                                optionLabel="name"
                                optionValue="corporationId"
                                placeholder="Select an Corporation"
                                :options="corporations"
                                :loading="isCorporationLoading"
                                show-clear
                                @change="getRegionByCorp(corporationId)"
                            />
                            <label for="corporationSearch">Corporation</label>
                        </span>

                        <span class="p-float-label w-full">
                            <Dropdown
                                v-model="regionId"
                                filter
                                inputId="region"
                                class="w-full"
                                optionLabel="name"
                                optionValue="regionId"
                                placeholder="Select a Region"
                                :options="regions"
                                :disabled="isRegionDisabled"
                                :loading="isRegionLoading"
                                show-clear
                                @change="getDivisionsByRegion(regionId)"
                            />
                            <label for="region">Region</label>
                        </span>

                        <span class="p-float-label w-full">
                            <Dropdown
                                v-model="divisionId"
                                inputId="division"
                                class="w-full"
                                optionLabel="name"
                                optionValue="divisionId"
                                placeholder="Select a Division"
                                :options="divisions"
                                :disabled="isDivisionDisabled"
                                :loading="isDivisionLoading"
                                filter
                                show-clear
                                @change="getFacilitiesByDivision(divisionId)"
                            />
                            <label for="division">Division</label>
                        </span>

                        <span class="p-float-label w-full">
                            <Dropdown
                                v-model="facilityId"
                                inputId="facility"
                                class="w-full"
                                optionLabel="name"
                                optionValue="facilityId"
                                placeholder="Select a Facility"
                                :options="facilities"
                                :disabled="isFacilityDisabled"
                                :loading="isFacilityLoading"
                                filter
                                show-clear
                            />
                            <label for="facility">Facility</label>
                        </span>

                        <div class="flex justify-content-center">
                            <Button
                                icon="pi pi-search" 
                                label="Search" 
                                aria-label="Search" 
                                @click="executeSearch" 
                                :loading="isLoading" 
                                :disabled="noOrgSelected" 
                            />
                        </div> 
        
                    </div>
                    
                    <div class="flex w-full">
                        <DataTable
                            :rows="10"
                            :rows-per-page-options="[10, 20, 40 , 60]"
                            :total-records="totalRecords"
                            :value="courseOrgAccessFromSearch"
                            :loading="isLoading"
                            v-model:filters="courseOrgAccessFilters"
                            scrollHeight="35rem"
                            paginator
                            scrollable
                            lazy
                            class="w-full pb-3 pb-3 mt-3 md:mt-5"
                            @page="onPage"
                        >
                            <Column header="Organization Level" field="organizationLevel" />
                            <Column header="Organization Name" field="name" />
                            <Column header="Modify Access">
                                <template #body="{ data }">
                                    <div>
                                        <Button 
                                            v-if="data.isAllowedAccessToCourse" 
                                            label="Remove Access" 
                                            @click="revokeCourseAccess(data)" 
                                            severity="danger"
                                        />
                                        <Button 
                                            v-else 
                                            label="Enable Access" 
                                            @click="enableCourseAccess(data)" 
                                            severity="success" 
                                        />
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>

            <TabPanel header="Assign By Group">
                <form @submit="onSubmit">
                    
                    <div class="flex flex-column">

                        <div class="mt-5 w-full mr-3 min-w-150px">
                            <Skeleton v-if="isLoading" height="2rem"/>
                            <div v-else class="flex flex-column align-items-center">
                                <span class="h-2rem">Skilled Nursing</span>
                                <InputSwitch v-model="allowAccessToSkilledNursing" :disabled="isLoading"/> 
                            </div>
                        </div>
                
                        <div class="mt-5 w-full mr-3 min-w-150px">
                            <Skeleton v-if="isLoading" height="2rem"/>
                            <div v-else class="flex flex-column align-items-center">
                                <span class="h-2rem">eCompetency</span>
                                <InputSwitch v-model="allowAccessToeCompetency" :disabled="isLoading"/> 
                            </div>
                        </div>
                    
                        <div class="mt-5 w-full mr-3 min-w-150px">
                            <Skeleton v-if="isLoading" height="2rem"/>
                            <div v-else class="flex flex-column align-items-center">
                                <span class="h-2rem">Assisted Living</span>
                                <InputSwitch v-model="allowAccessToAssistedLiving" :disabled="isLoading"/> 
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row justify-content-end pb-4 mt-5">
                        <Button
                            label="Save" 
                            icon="pi pi-save"
                            icon-pos="right"
                            type="submit"
                        />
                    </div>
                </form>
            </TabPanel>
        </TabView>
        
    </div>
</template>
