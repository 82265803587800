<script setup lang="ts">
import {computed, onBeforeMount, watch} from 'vue';
import useAccountStore from '@/store/account.store';
import useMainStore from '@/store/main.store';
import useOrganizationHierarchy from "@/composition-api/useOrganizationHierarchy";
import UserOrgLevels from '@/models/user/OrganizationLevel/IUserOrgLevels';
import IOrganizationHierarchy from "@/models/user/OrganizationLevel/IOrganizationHierarchy";
import ISimpleCorporation from "@/models/user/OrganizationLevel/Corporation/ISimpleCorporation";
import ISimpleRegion from "@/models/user/OrganizationLevel/Region/ISimpleRegion";
import ISimpleDivision from "@/models/user/OrganizationLevel/Division/ISimpleDivision";
import ISimpleFacility from "@/models/user/OrganizationLevel/Facility/ISimpleFacility";
import ISimpleDepartment from "@/models/user/OrganizationLevel/Department/ISimpleDepartment";

const mainStore = useMainStore();
const accountStore = useAccountStore();
const {
    corporationId,
    regionId,
    divisionId,
    facilityId,
    departmentId,

    corporations,
    regions,
    divisions,
    facilities,
    departments,

    isCorporationLoading,
    isRegionLoading,
    isDivisionLoading,
    isFacilityLoading,
    isDepartmentLoading,

    isRegionDisabled,
    isDivisionDisabled,
    isFacilityDisabled,
    isDepartmentDisabled,

    shouldShowCorporation,
    shouldShowRegion,
    shouldShowDivision,
    shouldShowFacility,
    shouldShowDepartment,
    
    getCorporations,
    getRegionByCorp,
    getDivisionsByRegion,
    getFacilitiesByDivision,
    getDepartmentsByFacility,
    manualInitialization,
    initializationComplete
    
} = useOrganizationHierarchy();

const isLoading = computed<boolean>(() => mainStore.isBusy);

const orgHierarchy = computed<IOrganizationHierarchy>(() => ({
    corporation: accountStore.getActiveUserProfile.corporation ?? {} as ISimpleCorporation,
    region: accountStore.getActiveUserProfile.region ?? {} as ISimpleRegion,
    division: accountStore.getActiveUserProfile.division ?? {} as ISimpleDivision,
    facility: accountStore.getActiveUserProfile.facility ?? {} as ISimpleFacility,
    department: accountStore.getActiveUserProfile.department ?? {} as ISimpleDepartment
} as IOrganizationHierarchy));

onBeforeMount(() => manualInitialization.value = true);

watch(initializationComplete, async (newVal, oldVal) => {
    if (newVal === oldVal)
        return;

    if (initializationComplete.value)
        await initializeValues();
})

async function initializeValues() {
    const corporation = orgHierarchy.value?.corporation;

    if (shouldShowCorporation.value) {
        await getCorporations();
        await getRegionByCorp(corporation?.corporationId);
        corporationId.value = corporation?.corporationId;
    } else {
        corporations.value = corporation ? [corporation] : [];
        corporationId.value = corporation?.corporationId;
    }    
    
    const region = orgHierarchy.value?.region;
    if (shouldShowRegion.value) {
        await getDivisionsByRegion(region?.regionId);
        regionId.value = region?.regionId;
    }
    else {
        regions.value = region ? [region] : [];
        regionId.value = region?.regionId;
    }

    const division = orgHierarchy.value?.division;
    if (shouldShowDivision.value) {
        await getFacilitiesByDivision(division?.divisionId);
        divisionId.value = division?.divisionId;
    }
    else {
        divisions.value = division ? [division] : [];
        divisionId.value = division?.divisionId;
    }
    
    const facility = orgHierarchy.value?.facility;
    if (shouldShowFacility.value) {
        await getDepartmentsByFacility(facility?.facilityId);
        facilityId.value = facility?.facilityId;
    }
    else {
        facilities.value = facility ? [facility] : [];
        facilityId.value = facility?.facilityId;
    }
    
    if (shouldShowDepartment.value) {
        departmentId.value = orgHierarchy.value?.department?.departmentId;
    }
}

async function updateUserOrgLevels() {
    const userProfileId = accountStore.getActiveUserProfileId;

    if (!userProfileId)
        return mainStore.setErrorMsg('User Id not found, please refresh page.');

    const updateOrgMdl: UserOrgLevels = {
        userProfileId: userProfileId,
        corporationId: corporationId.value,
        regionId: regionId.value,
        divisionId: divisionId.value,
        facilityId: facilityId.value,
        departmentId: departmentId.value
    }
    
    await accountStore.updateUserOrgLevels(updateOrgMdl);
}

</script>

<style scoped lang="scss">

.user-org-container {
    background-color: var(--hca-form-bg);
    border-radius: 10px;
    padding: 1rem;
    gap: 2rem;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .org-search {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2rem;
            min-width: 260px;
            
            > div {
                display: flex;
                justify-content: center;
        
                &:first-child {
                    margin-top: 2rem;
                }
        
                span {
                    width: 100%;
        
                    > div {
                        width: 100%;
                    }
                }
            }
        
            .org-dept-search {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 2rem;
            }
        }
        
        .update-action {
            display: flex;
            justify-content: center;
        }
    }
}

</style>

<template>
    <section class="user-org-container">
        <form @submit.prevent="updateUserOrgLevels">

            <div class="org-search">
                        
                <div v-if="shouldShowCorporation">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="corporationId"
                            input-id="corporation"
                            option-label="name"
                            option-value="corporationId"
                            placeholder="Select a Corporation"
                            filter
                            show-clear
                            :options="corporations"
                            :loading="isCorporationLoading"
                            @change="getRegionByCorp(corporationId)"
                        />
                        <label for="corporation">Corporation</label>
                    </span>
                </div>
    
                <div v-if="shouldShowRegion">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="regionId"
                            input-Id="region"
                            option-label="name"
                            option-value="regionId"
                            placeholder="Select a Region"
                            filter
                            show-clear
                            :options="regions"
                            :disabled="isRegionDisabled"
                            :loading="isRegionLoading"
                            @change="getDivisionsByRegion(regionId)"
                        />
                        <label for="region">Region</label>
                    </span>
                </div>
    
                <div v-if="shouldShowDivision">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="divisionId"
                            input-Id="division"
                            option-label="name"
                            option-value="divisionId"
                            placeholder="Select a Division"
                            filter
                            show-clear
                            :options="divisions"
                            :disabled="isDivisionDisabled"
                            :loading="isDivisionLoading"
                            @change="getFacilitiesByDivision(divisionId)"
                        />
                        <label for="division">Division</label>
                    </span>
                </div>
    
                <div v-if="shouldShowFacility">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="facilityId"
                            input-Id="facility"
                            option-label="name"
                            option-value="facilityId"
                            placeholder="Select a Facility"
                            filter
                            show-clear
                            :options="facilities"
                            :disabled="isFacilityDisabled"
                            :loading="isFacilityLoading"
                            @change="getDepartmentsByFacility(facilityId)"
                        />
                        <label for="facility">Facility</label>
                    </span>
                </div>
    
                <div v-if="shouldShowDepartment">
                    <span class="p-float-label">
                        <Dropdown
                            v-model="departmentId"
                            input-Id="department"
                            option-label="name"
                            option-value="departmentId"
                            placeholder="Select a Department"
                            filter
                            show-clear
                            :options="departments"
                            :disabled="isDepartmentDisabled"
                            :loading="isDepartmentLoading"
                        />
                        <label for="department">Department</label>
                    </span>
                </div>

            </div>

            <div class="update-action">
                <Button
                    label="Save"
                    type="submit"
                    :loading="isLoading"
                />
            </div>


        </form>

    </section>
</template>
