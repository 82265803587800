<script setup lang="ts">
import useAuthStore from '@/store/auth.store';
import Menu from 'primevue/menu';
import { MenuItem } from 'primevue/menuitem';
import { onMounted, ref } from 'vue';
import router from '@/router';

const authStore = useAuthStore();

const displayName = ref<string>('');
const menu = ref<Menu>();
const userActionItems: MenuItem[] = [
    {
        label: 'Student',
        icon: 'student-icon',
        visible: () => !!authStore.getStudentProfileId,
        command: () => router.push('/home'),
    },
    {
        label: 'Admin',
        icon: 'admin-icon',
        visible: () => authStore.isAdmin,
        command: () => router.push('/admin-menu'),
    },
    {
        label: 'Evaluator',
        icon: 'evaluator-icon',
        visible: () => authStore.isAdmin && authStore.hasAnyCompetencyEvaluatorPermission,
        command: () => router.push('/evaluator-menu'),
    },
    {
        separator: true,
        class: 'mx-5',
        style: 'border-top-color: var(--hca-main-gray)',
        visible: () => authStore.isAdmin,
    },
    {
        label: 'My Profile',
        icon: 'my-profile-icon',
        command: () => authStore.isAdmin ? router.push('/account/admin') : router.push('/account/student/overview'),
    },
    {
        label: 'Log Out',
        icon: 'log-out-icon',
        command: () => router.push('/logout'),
    },
    {
        label: 'Help',
        icon: 'help-icon',
        command: () => window.open('https://healthcareacademy.com/contact-support/', '_blank', 'noreferrer'),
    },
];

onMounted(() => setDisplayName());

function setDisplayName() {
    let name = `${authStore.getFirstName} ${authStore.getLastName}`

    if (!name) return;

    if (name.length > 15) {
        name = `${authStore.getFirstName.charAt(0).toUpperCase()}. ${authStore.getLastName}`
    }

    displayName.value = name;
}

function toggleMenu(event: any): void {
    menu.value?.toggle(event);
}

</script>
<template>
    <div
        class="flex flex-row justify-content-between z-0 w-full"
        style="
            background-color: var(--hca-light-gray);
            box-shadow: 0 0 5px 0;
        "
    >
        <div class="m-2">
            <router-link to="/">
                <img
                    class="flex h-3rem"
                    src="https://hcacontenthosting.blob.core.windows.net/webdocs/Images/HCA-Logo.png"
                    alt="Healthcare Academy Logo"
                />
            </router-link>
        </div>

        <div class="flex align-items-end">
            <Button
                icon="pi pi-angle-down"
                icon-pos="right"
                type="button"
                class="h-full px-2 md:pr-7"
                style="color: var(--hca-main-gray); font-size: 15px;"
                :label="displayName"
                :pt="{ label: { class: ['font-medium'] } }"
                @click="toggleMenu"
                text
            />
        </div>

        <Menu
            :model="userActionItems"
            ref="menu"
            class="py-0"
            style="border-radius: 15px; "
            :popup="true"
        />
    </div>
</template>

