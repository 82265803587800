import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { msalPlugin } from '@/plugins/msalPlugin';
import { makeMsalInstance } from '@/authConfig';
import useConfigStore from '@/store/config.store';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import App from './App.vue';
import router from './router'
import AppLayout from './layouts/AppLayout.vue'

import TopMenuComponent from './components/TopMenuComponent.vue'
import LoginTopMenuComponent from './components/Login/LoginTopMenuComponent.vue'

import AdminMenu from '@/components/AdminMenu.vue';

import LoginFooterComponent from './components/Login/LoginFooterComponent.vue'
import FooterComponent from './components/Footer.vue'

import UpdateProfileInfoComponent from './components/Account/UpdateProfileInfoComponent.vue'
import ResetPasswordComponent from './components/Account/ResetPasswordComponent.vue'
import UserOrgComponent from './components/Account/UserOrgComponent.vue';
import PromoteUserRoleComponent from './components/Account/PromoteUserRoleComponent.vue';
import UserPermissionsComponent from './components/Account/UserPermissionsComponent.vue';
import UserCourseAssignmentComponent from './components/Account/UserCourseAssignment.vue';

import StudentProfessions from './components/Student/StudentProfessions.vue';
import EvaluationPersonInfoUpdate from './components/Student/EvaluationPersonInfoUpdate.vue';

import LessonGeneralComponent from '@/components/Course/Lesson/LessonGeneral.vue';
import LessonEntryComponent from '@/components/Course/Lesson/LessonEntry.vue';
import LessonInterfaceComponent from '@/components/Course/Lesson/LessonInterface.vue';
import LessonOtherComponent from '@/components/Course/Lesson/LessonOther.vue';

import CourseGeneralComponent from '@/components/Course/Course/CourseGeneral.vue';
import CourseInterfaceComponent from '@/components/Course/Course/CourseInterface.vue';
import CourseContentComponent from '@/components/Course/Course/CourseContent.vue';
import CourseAccessComponent from '@/components/Course/Course/CourseAccess.vue';
import CoursePrerequisitesComponent from '@/components/Course/Course/CoursePrerequisites.vue';
import CourseOtherComponent from '@/components/Course/Course/CourseOther.vue';

import AddAssignmentPreference from '@/components/Course/CourseAssignment/AssignmentPreferences/AddAssignmentPreference.vue';
import EditAssignmentPreference from '@/components/Course/CourseAssignment/AssignmentPreferences/EditAssignmentPreference.vue';
import NewHireAssignmentOverview from '@/components/Course/CourseAssignment/NewHire/Overview.vue';
import AnnualAssignmentOverview from '@/components/Course/CourseAssignment/Annual/Overview.vue';
import RollingAssignmentOverview from '@/components/Course/CourseAssignment/Rolling/Overview.vue';

import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FloatLabel from 'primevue/floatlabel';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Paginator from 'primevue/paginator';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import TieredMenu from 'primevue/tieredmenu';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ToggleButton from 'primevue/togglebutton';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';

import 'primevue/resources/themes/aura-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@/assets/styles.scss';

const app = createApp(App)
    .use(createPinia());

app.use(PrimeVue);
app.use(ToastService);
app.use(router);

app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);

// PrimeVUE components
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Chips', Chips);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FloatLabel', FloatLabel);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Paginator', Paginator);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Toast', Toast);
app.component('ToggleButton', ToggleButton);
app.component('Toolbar', Toolbar);

// Application custom components
app.component('AppLayout', AppLayout);
app.component('TopMenuComponent', TopMenuComponent);
app.component('LoginTopMenuComponent', LoginTopMenuComponent);
app.component('LoginFooterComponent', LoginFooterComponent);
app.component('FooterComponent', FooterComponent);

app.component('UpdateProfileInfoComponent', UpdateProfileInfoComponent);
app.component('ResetPasswordComponent', ResetPasswordComponent);
app.component('UserOrgComponent', UserOrgComponent);
app.component('UserPermissionsComponent', UserPermissionsComponent);
app.component('PromoteUserRoleComponent', PromoteUserRoleComponent);
app.component('UserCourseAssignmentComponent', UserCourseAssignmentComponent);
app.component('StudentProfessions', StudentProfessions);
app.component('EvaluationPersonInfoUpdate', EvaluationPersonInfoUpdate);

app.component('AdminMenu', AdminMenu);

// Lesson Components
app.component('LessonGeneralComponent', LessonGeneralComponent);
app.component('LessonEntryComponent', LessonEntryComponent);
app.component('LessonInterfaceComponent', LessonInterfaceComponent);
app.component('LessonOtherComponent', LessonOtherComponent);

// Course Components
app.component('CourseGeneralComponent', CourseGeneralComponent);
app.component('CourseInterfaceComponent', CourseInterfaceComponent);
app.component('CourseContentComponent', CourseContentComponent);
app.component('CourseAccessComponent', CourseAccessComponent);
app.component('CoursePrerequisitesComponent', CoursePrerequisitesComponent);
app.component('CourseOtherComponent', CourseOtherComponent);

// Automated Course Assignment
app.component('AddAssignmentPreference', AddAssignmentPreference);
app.component('EditAssignmentPreference', EditAssignmentPreference);
app.component('NewHireAssignmentOverview', NewHireAssignmentOverview);
app.component('AnnualAssignmentOverview', AnnualAssignmentOverview);
app.component('RollingAssignmentOverview', RollingAssignmentOverview);

router.isReady().then(async () => {
    const configStore = useConfigStore();
    await configStore.initialize();

    const msalInstance = makeMsalInstance();

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    app.use(msalPlugin, msalInstance);

    const appInsights: ApplicationInsights = new ApplicationInsights({ config: {
            connectionString: configStore.appInsights,
        }});

    appInsights.loadAppInsights();
    appInsights.trackPageView();

    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    app.mount('#app');
});
