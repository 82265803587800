<style scoped lang="scss">

.copyright-overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 40px;
    text-align: center;
    z-index: 1;

    @media screen and (min-width: 768px) {
        bottom: 5px;
    }
    
    span {
        font-size: 15px;
        font-weight: 700;
        margin: 0;
    }
}

.decorative-triangles {
    position: fixed;
    bottom: 10rem;
    z-index: -1;

    @media screen and (min-width: 768px) {
        display: none;
    }
    
    .bottom-left {
        position: absolute;
        border-bottom: 10rem solid #C8C2A6;
        border-right: 100vw solid transparent;
    }
    
    .bottom-right {
        position: absolute;
        border-bottom: 10rem solid #C8C2A6;
        border-left: 100vw solid transparent;
    }
}

.support-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    color: whitesmoke;
    background: var(--footer-gray);
    width: 100%;
    
    @media screen and (min-width: 768px) {
        display: none;
    }

    .support-label {
        display: flex;
    }
        
    .phone-support {
        border-left: 2px solid whitesmoke;
        border-right: 2px solid whitesmoke;
        
        button { 
            border: none;
            color: whitesmoke;
            padding: 0 3rem;
        }
    }
    
    .email-support {
        
        button {
            border: none;
            color: whitesmoke;
        }
    }
}

</style>

<template>
    <section>

        <div class="copyright-overlay">
            <span >Copyright &#169; Healthcare Academy {{ new Date().getFullYear() }}. All Rights Reserved.</span>
        </div>

        <div class="decorative-triangles">
            <div class="bottom-left" />
            <div class="bottom-right" />
        </div>

        <div class="support-footer" >

            <div class="support-label">
                <span>Need support?</span>
            </div>

            <div class="phone-support">
                <Button 
                    icon="pi pi-phone"
                    outlined
                />
            </div>

            <div class="email-support">
                <Button 
                    icon="pi pi-envelope"
                    outlined
                />
            </div>

        </div>
        
    </section>
</template>
