<script setup lang="ts">
import useAuthStore from '@/store/auth.store';
import useAccountStore from '@/store/account.store';
import useMainStore from '@/store/main.store';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import PromoteUserRole from '@/models/auth/PromoteUserRole';
import {toTypedSchema} from '@vee-validate/zod';
import {useField, useForm} from 'vee-validate';
import {computed, ref} from 'vue';
import * as zod from 'zod';

const authStore = useAuthStore();
const mainStore = useMainStore();
const accountStore = useAccountStore();

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            newRoleAuthorizationLevel: zod.number().gte(400)
        })
    )
});

const { value: newRoleAuthorizationLevel } = useField<number>('newRoleAuthorizationLevel');
const adminLevel = authStore.getAuthorizationLevel;
const promotionConfirmation = ref<boolean>(false);

const promotionOrg = computed<string>(() => roleOptions.value.find(ro => ro.authLevel == newRoleAuthorizationLevel.value)?.roleName.split(" ")[0] ?? 'No Promotion');
const userDisplayName = computed<string>(() => accountStore.getActiveUserProfile.displayName);
const currentUserRole = computed<string>(() => accountStore.getActiveUserProfile.identityUser?.role ?? '');
const orgNameOfPromotion = computed<string>(() => {
   switch (newRoleAuthorizationLevel.value) {
       case AuthorizationLevel.CorporationAdministrator:
           return accountStore.getActiveUserProfile.corporation?.name ?? 'Corporation not found';
       case AuthorizationLevel.RegionAdministrator:
           return accountStore.getActiveUserProfile.region?.name ?? 'Region not found';
       case AuthorizationLevel.DivisionAdministrator:
           return accountStore.getActiveUserProfile.division?.name ?? 'Division not found';
       case AuthorizationLevel.FacilityAdministrator:
           return accountStore.getActiveUserProfile.facility?.name ?? 'Facility not found';
       case AuthorizationLevel.DepartmentAdministrator:
           return accountStore.getActiveUserProfile.department?.name ?? 'Department not found';
       default:
           return 'Unable to Promote';
   }
});
const roleOptions = computed<{ roleName: string, authLevel: AuthorizationLevel }[]>(() => {
    const roles = [
        { roleName: 'System Admin', authLevel: AuthorizationLevel.SystemAdministrator },
        { roleName: 'Corporation Admin', authLevel: AuthorizationLevel.CorporationAdministrator },
        { roleName: 'Region Admin', authLevel: AuthorizationLevel.RegionAdministrator },
        { roleName: 'Division Admin', authLevel: AuthorizationLevel.DivisionAdministrator },
        { roleName: 'Facility Admin', authLevel: AuthorizationLevel.FacilityAdministrator },
        { roleName: 'Department Admin', authLevel: AuthorizationLevel.DepartmentAdministrator }
    ];

    return roles
        .filter(role => role.authLevel <= adminLevel && !currentUserRole.value.includes(role.roleName))
});

const confirmPromotion = handleSubmit(async (data) => {
    const { newRoleAuthorizationLevel } = data;

    switch (newRoleAuthorizationLevel) {
        case AuthorizationLevel.CorporationAdministrator: {
            const userCorporation = accountStore.getActiveUserProfile.corporation;

            if (!userCorporation?.corporationId)
                return mainStore.setErrorMsg('Unable to promote User. Please assign corporation to user.')

            break;
        }
        case AuthorizationLevel.RegionAdministrator: {
            const userRegion = accountStore.getActiveUserProfile.region;

            if (!userRegion?.regionId)
                return mainStore.setErrorMsg('Unable to promote User. Please assign region to user.')

            break;
        }
        case AuthorizationLevel.DivisionAdministrator: {
            const userDivision = accountStore.getActiveUserProfile.division;

            if (!userDivision?.divisionId)
                return mainStore.setErrorMsg('Unable to promote User. Please assign division to user.')

            break;
        }
        case AuthorizationLevel.FacilityAdministrator: {
            const userFacility = accountStore.getActiveUserProfile.facility;

            if (!userFacility?.facilityId)
                return mainStore.setErrorMsg('Unable to promote User. Please assign facility to user.')

            break;
        }
        case AuthorizationLevel.DepartmentAdministrator: {
            const userDept = accountStore.getActiveUserProfile.department;

            if (!userDept?.departmentId)
                return mainStore.setErrorMsg('Unable to promote User. Please assign department to user.')

            break;
        }
        default:
            return mainStore.setErrorMsg('Invalid Authorization level selected.');
    }

    promotionConfirmation.value = true;
});

async function promoteUser() {
    const userProfileId = accountStore.getActiveUserProfileId;

    if (!userProfileId)
        return mainStore.setErrorMsg('Invalid User Profile Id');

    const corporation = accountStore.getActiveUserProfile.corporation;
    const region = accountStore.getActiveUserProfile.region;
    const division = accountStore.getActiveUserProfile.division;
    const facility = accountStore.getActiveUserProfile.facility;
    const department = accountStore.getActiveUserProfile.department;

    const promoteUserRoleMdl: PromoteUserRole = {
        userProfileId: userProfileId,
        newAdminLevel: newRoleAuthorizationLevel.value as AuthorizationLevel,
        corporationId: corporation?.corporationId,
        regionId: region?.regionId,
        divisionId: division?.divisionId,
        facilityId: facility?.facilityId,
        departmentId: department?.departmentId
    }

    await authStore.promoteUserRole(promoteUserRoleMdl)
        .then(() => {
            accountStore.updateProfileRole(`${promotionOrg.value} Administrator`)

            resetForm();
        })
        .catch(() => {});

    promotionConfirmation.value = false;
}

</script>

<template>
<div class="flex justify-content-center border-round-lg" style="background-color: var(--hca-form-bg)">

    <form @submit="confirmPromotion" class="flex my-5 gap-4">

        <div class="flex flex-column md:flex-row gap-4">

            <div class="flex align-items-center">
                <span class="font-bold">{{ currentUserRole }}</span>
            </div>

            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-arrow-right hidden md:inline-block" style="font-size: 20px" />
                <i class="pi pi-arrow-down md:hidden" style="font-size: 20px" />
            </div>

            <div>
                <div class="p-float-label">
                    <Dropdown
                        input-id="dd-rolePromotion"
                        v-model="newRoleAuthorizationLevel"
                        option-label="roleName"
                        option-value="authLevel"
                        class="w-full"
                        placeholder="Select new role"
                        :options="roleOptions"
                        :invalid="!!errors.newRoleAuthorizationLevel"
                        :class="{ 'p-invalid' : errors.newRoleAuthorizationLevel }"
                    />
                    <label for="dd-rolePromotion" class="font-normal">New User Role</label>
                </div>
                <small class="p-error"> {{ errors["newRoleAuthorizationLevel"] }} </small>
            </div>

        </div>

        <div class="flex justify-content-end">
            <Button
                label="Promote"
                type="submit"
            />
        </div>

    </form>

    <Dialog
        v-model:visible="promotionConfirmation"
        modal
        header="Are You Sure?"
        :draggable="false"
    >
        <div class="flex flex-column gap-2 mb-5">
            <span class="p-text-secondary">Promote <span class="font-semibold">{{ userDisplayName }}</span> to <span class="font-semibold">{{ promotionOrg }} Admin</span></span>

            <span class="p-text-secondary">{{ promotionOrg }}: <span class="font-semibold">{{ orgNameOfPromotion }}</span></span>
        </div>


        <div class="flex justify-content-end gap-2">
            <Button
                type="button"
                label="Cancel"
                severity="secondary"
                @click="promotionConfirmation = false"
            />
            <Button
                type="button"
                label="Save"
                @click="promoteUser"
            />
        </div>
    </Dialog>

</div>
</template>
