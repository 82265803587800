import useMainStore from '@/store/main.store';
import jwtInterceptor from '@/helpers/http';
import {formatServerErrorResponse} from '@/helpers/jwtHelper';
import AddAdminOrganizationAccess from '@/models/user/OrganizationLevel/AddAdminOrganizationAccess';
import AdminOrganizationAccess from '@/models/user/OrganizationLevel/AdminOrganizationAccess';
import IOrganizationHierarchy from '@/models/user/OrganizationLevel/IOrganizationHierarchy';
import UserOrgLevels from '@/models/user/OrganizationLevel/IUserOrgLevels';
import IUserSearchResult from '@/models/search/user/IUserSearchResult';
import IUserProfile from '@/models/user/IUserProfile';
import IGridFilter from '@/models/search/IGridFilter';
import UpdateUser from '@/models/user/updateUser';
import AddUser from '@/models/user/addUser';
import {computed, ComputedRef, Ref} from 'vue';
import {useLocalStorage} from '@vueuse/core';
import {defineStore} from 'pinia';
import {AxiosError, AxiosResponse} from 'axios';

export interface IAccountStore {
    userProfileForActiveUser: Ref<IUserProfile>,
    getActiveUserProfile: ComputedRef<IUserProfile>,
    getActiveUserProfileId: ComputedRef<number | undefined>,

    updateProfileRole(newRole: string): void,
    searchUsers(filter: IGridFilter): Promise<IUserSearchResult>
    getUserProfileByUserProfileId(userProfileId: number, includeAdditionalProfiles: boolean): Promise<IUserProfile | void>,
    updateProfile(updatedProfile: UpdateUser): Promise<void>,
    createUser(newUserProfile: AddUser): Promise<void>,
    updateUserOrgLevels(updateMdl: UserOrgLevels): Promise<void>,
    getAdminHierarchy(identityId: string): Promise<IOrganizationHierarchy>,
    getAllAdminOrganizationAccess(userProfileId: number): Promise<AdminOrganizationAccess>,
    addAdminOrganizationAccess(addOrgAccess: AddAdminOrganizationAccess): Promise<void>,
    removeAdminOrganizationAccess(userProfileId: number, organizationLevel: string, organizationId: number): Promise<void>,
    $reset(): void
}

const useAccountStore = defineStore('accountStore', (): IAccountStore => {
    const mainStore= useMainStore();

    const userProfileForActiveUser: Ref<IUserProfile> = useLocalStorage('accountStore_userdata', {} as IUserProfile);

    const getActiveUserProfile: ComputedRef<IUserProfile> = computed<IUserProfile>(() => userProfileForActiveUser.value);
    const getActiveUserProfileId: ComputedRef<number | undefined> = computed<number | undefined>(() => userProfileForActiveUser.value.userId);


    function updateProfileRole(newRole: string): void {
        if (!userProfileForActiveUser.value.identityUser)
            return mainStore.setErrorMsg('User does not contain role');

        userProfileForActiveUser.value.identityUser.role = newRole;
    }

    async function searchUsers(filter: IGridFilter): Promise<IUserSearchResult> {
        const actionName = 'searchUsers';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchResult: AxiosResponse<IUserSearchResult> = await jwtInterceptor.get('api/userProfile/search', {params: {filter: btoa(filterJson)}});

            return searchResult.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return User search result', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getUserProfileByUserProfileId(userProfileId: number, includeAdditionalProfiles: boolean = false) : Promise<IUserProfile | void> {
        const actionName = 'getUserProfileByUserProfileId';
        mainStore.startTask(actionName);

        try {
            const getProfileResponse: AxiosResponse<IUserProfile>  = await jwtInterceptor.get('api/userProfile', { params: { id: userProfileId, includeAdditionalProfiles } })

            userProfileForActiveUser.value = getProfileResponse.data;

            return userProfileForActiveUser.value;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Error fetching User Profile', error)

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName)
        }
    }

    async function updateProfile(updatedProfile: UpdateUser): Promise<void> {
        const actionName = 'updateProfile';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/userprofile/update', updatedProfile);

        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Error updating User Profile.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function createUser(newUserProfile: AddUser): Promise<void> {
        const actionName = 'CreateUser';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/UserProfile/create', newUserProfile);

            mainStore.setSuccessMsg('Successfully created user.');
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Create User', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateUserOrgLevels(updateMdl: UserOrgLevels): Promise<void> {
        const actionName = 'updateUserOrgLevels';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.put('api/UserProfile/update-user-org', updateMdl);

            mainStore.setInfoMsg('Successfully updated user organizations.');
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Update user Organization', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAdminHierarchy(identityId: string): Promise<IOrganizationHierarchy> {
        const actionName = 'getAdminHierarchy';
        mainStore.startTask(actionName);

        try {
            const getAdminHierarchyResponse: AxiosResponse<IOrganizationHierarchy> = await jwtInterceptor.get('api/UserProfile/admin-hierarchy', { params: { identityId } });

            return getAdminHierarchyResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Admin Hierarchy', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAllAdminOrganizationAccess(userProfileId: number): Promise<AdminOrganizationAccess> {
        const actionName = 'getAllAdminOrganizationAccess';
        mainStore.startTask(actionName);

        try {
            const getAdminOrgAccessResponse: AxiosResponse<AdminOrganizationAccess> = await jwtInterceptor.get('api/UserProfile/admin-org-access', { params: { userProfileId } })

            return getAdminOrgAccessResponse.data;
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Admin Organization Access list', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    /**
     * Adds the specified organization access for an admin user.
     *
     * @param addOrgAccess - The organization access to add.
     * @returns A promise that resolves when the organization access is added.
     */
    async function addAdminOrganizationAccess(addOrgAccess: AddAdminOrganizationAccess): Promise<void> {
        const actionName = 'addAdminOrganizationAccess';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/UserProfile/add-admin-org-access', addOrgAccess);
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to add Admin Organization Access', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    /**
     * Removes the specified organization access for an admin user.
     *
     * @param userProfileId - The ID of the user profile.
     * @param organizationLevel - The level of the organization (e.g., Corporation, Region, Division, Facility, Department).
     * @param organizationId - The ID of the organization.
     * @returns A promise that resolves when the organization access is removed.
     */
    async function removeAdminOrganizationAccess(userProfileId: number, organizationLevel: string, organizationId: number): Promise<void> {
        const actionName = 'removeAdminOrganizationAccess';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.delete(`api/UserProfile/remove-admin-org-access`, {
                params: { userProfileId, organizationLevel, organizationId }
            });
        } catch( error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to remove Admin Organization Access', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    function $reset() {
        userProfileForActiveUser.value = {} as IUserProfile;
    }

    return {
        userProfileForActiveUser,
        getActiveUserProfile,
        getActiveUserProfileId,

        updateProfileRole,
        searchUsers,
        getUserProfileByUserProfileId,
        updateProfile,
        createUser,
        updateUserOrgLevels,
        getAdminHierarchy,
        getAllAdminOrganizationAccess,
        addAdminOrganizationAccess,
        removeAdminOrganizationAccess,
        $reset
    }
});

export default useAccountStore;
