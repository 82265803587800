import AppLayout from '@/models/AppLayout';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import { RouteRecordRaw } from 'vue-router';

const studentAssignmentRoutes: RouteRecordRaw[] = [
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/student/Home.vue'),
        children: [
            {
                path: '',
                name: 'ToDoCourses',
                component: () => import('@/components/Student/StudentToDoCourses.vue'),
                meta: {
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.User,
                    layout: AppLayout.Student
                }
            },
            {
                path: 'skills',
                name: 'ToDoSkills',
                component: () => import('@/components/Student/StudentToDoSkills.vue'),
                meta: {
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.User,
                    layout: AppLayout.Student
                }
            },
            {
                path: 'completed-courses',
                name: 'CompletedCourses',
                component: () => import('@/components/Student/StudentCompletedCourses.vue'),
                meta: {
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.User,
                    layout: AppLayout.Student
                }
            },
            {
                path: 'completed-skills',
                name: 'CompletedSkills',
                component: () => import('@/components/Student/StudentCompletedSkills.vue'),
                meta: {
                    requiresAuth: true,
                    requiredAuthorizationLevel: AuthorizationLevel.User,
                    layout: AppLayout.Student
                }
            },
        ],
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/lesson-assignments',
        name: 'Student Lessons',
        component: () => import('@/views/student/LessonAssignments.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/skill-lesson-assignments',
        name: 'Student Skill Lessons',
        component: () => import('@/views/student/SkillLessonAssignments.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/learning-session',
        name: 'Learning Session',
        component: () => import('@/views/student/LearningSession.vue'),
        meta: {
            title: 'Learning Session',
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/skill/printable-checklist',
        name: 'View Printable Checklist',
        component: () => import('@/views/student/skill/PrintableChecklist.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/skill/video-lesson',
        name: 'Skill Video Lesson',
        component: () => import('@/views/student/skill/VideoLesson.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
    {
        path: '/skill/review-evaluation',
        name: 'Most Recent Evaluation',
        component: () => import('@/views/student/skill/ReviewMostRecentEvaluation.vue'),
        meta: {
            requiresAuth: true,
            requiredAuthorizationLevel: AuthorizationLevel.User,
            layout: AppLayout.Student
        }
    },
];

export default studentAssignmentRoutes;
