<script setup lang="ts">
import HealthcareAcademyPermissions from '@/models/auth/HealthcareAcademyPermissions';
import AuthorizationLevel from "@/models/auth/AuthorizationLevel";
import UserPermissions from '@/models/user/UserPermissions';
import useAccountStore from '@/store/account.store';
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import * as zod from 'zod';
import {computed, onMounted, ref} from 'vue';

const mainStore = useMainStore();
const authStore = useAuthStore();
const accountStore = useAccountStore();

const { handleSubmit, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            addEditAdministrator: zod.boolean(),
            addEditStudent: zod.boolean(),
            assignCourses: zod.boolean(),
            manageCourseResults: zod.boolean(),
            importStudents: zod.boolean(),
            dropCourses: zod.boolean(),
            competencyEvaluator: zod.boolean(),
            manageAutomatedAssignments: zod.boolean(),
            createCustomCompetencies: zod.boolean(),
        })
    )
});

const { value: addEditAdministrator } = useField<boolean>('addEditAdministrator');
const { value: addEditStudent } = useField<boolean>('addEditStudent');
const { value: assignCourses } = useField<boolean>('assignCourses');
const { value: manageCourseResults } = useField<boolean>('manageCourseResults');
const { value: importStudents } = useField<boolean>('importStudents');
const { value: dropCourses } = useField<boolean>('dropCourses');
const { value: competencyEvaluator } = useField<boolean>('competencyEvaluator');
const { value: manageAutomatedAssignments } = useField<boolean>('manageAutomatedAssignments');
const { value: createCustomCompetencies } = useField<boolean>('createCustomCompetencies');

const userProfileId = ref<number>();
const permissionCategories = ref([
    {
        key: 1,
        label: 'Course Management',
        icon: 'course-management-icon-white',
        categoryPermissions: [
            {
                key: 1,
                label: 'Assign Courses',
                inputId: 'assignCourses',
                hcaPermission: HealthcareAcademyPermissions.AllowCourseAssign,
                ref: assignCourses
            },
            {
                key: 2,
                label: 'Drop Courses',
                inputId: 'dropCourses',
                hcaPermission: HealthcareAcademyPermissions.DropCourse,
                ref: dropCourses
            },
            {
                key: 3,
                label: 'Enter/Edit Course Results',
                inputId: 'manageCourseResults',
                hcaPermission: HealthcareAcademyPermissions.ManageCourseResults,
                ref: manageCourseResults
            },
            {
                key: 4,
                label: 'Manage Automated Assignments',
                inputId: 'manageAutomatedAssignments',
                hcaPermission: HealthcareAcademyPermissions.ManageAutomatedAssignments,
                ref: manageAutomatedAssignments
            },
        ]
    },
    {
        key: 2,
        label: 'User Management',
        icon: 'user-management-icon-white',
        categoryPermissions: [
            {
                key: 1,
                label: 'Add/Edit Student',
                inputId: 'addEditStudent',
                hcaPermission: HealthcareAcademyPermissions.ManageStudents,
                ref: addEditStudent
            },
            {
                key: 2,
                label: 'Add/Edit Administrator',
                inputId: 'addEditAdministrator',
                hcaPermission: HealthcareAcademyPermissions.ManageAdmins,
                ref: addEditAdministrator
            },
            {
                key: 3,
                label: 'Import Students',
                inputId: 'importStudents',
                hcaPermission: HealthcareAcademyPermissions.AllowStudentImport,
                ref: importStudents
            },
        ]
    },
    {
        key: 3,
        label: 'eCompetencies Management',
        icon: 'evaluator-icon-white',
        categoryPermissions: [
            {
                key: 1,
                label: 'Evaluate Students',
                inputId: 'competencyEvaluator',
                hcaPermission: HealthcareAcademyPermissions.ManageCompetencyEvaluator,
                ref: competencyEvaluator
            },
            {
                key: 2,
                label: 'Create Custom Competencies',
                inputId: 'createCustomCompetencies',
                hcaPermission: HealthcareAcademyPermissions.CreateCustomCompetencies,
                ref: createCustomCompetencies
            },
        ]
    }
]);
const isLoading = computed<boolean>(() => mainStore.isBusy);

onMounted(() => initializePermissions())

async function initializePermissions() {
    userProfileId.value = accountStore.getActiveUserProfileId;

    if (!userProfileId.value)
        return mainStore.setErrorMsg('User Id not found, please refresh page.');

    await authStore.getUserPermissions(userProfileId.value)
        .then((permResult) => populateWithPermissions(permResult))
        .catch();
}

function populateWithPermissions(permResult: UserPermissions | void) {
    const permissions : string[] = [];
    permissions.push(...permResult?.permissionsList ?? []);

    if (!permissions) return;

    resetForm({
        values: {
            addEditAdministrator: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.ManageAdmins),
            addEditStudent: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.ManageStudents),
            assignCourses: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.AllowCourseAssign),
            manageCourseResults: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.ManageCourseResults),
            importStudents: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.AllowStudentImport),
            dropCourses: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.DropCourse),
            competencyEvaluator: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.ManageCompetencyEvaluator),
            manageAutomatedAssignments: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.ManageAutomatedAssignments),
            createCustomCompetencies: caseInsensitiveArraySearch(permissions, HealthcareAcademyPermissions.CreateCustomCompetencies),
        }
    });
}

function caseInsensitiveArraySearch(perms: string[], searchValue: string) : boolean {
    return perms.some(e => e.toLowerCase() === searchValue.toLowerCase());
}

function isAuthorizedToDisplay(permission: HealthcareAcademyPermissions): boolean {
    const authLevel = authStore.getAuthorizationLevel;
    
    if (authLevel === AuthorizationLevel.SystemAdministrator)
        return true;
    
    return authStore.getLoggedInUsersHcaPermissions.some(e => e.toLowerCase() === permission.toLowerCase());
}

function hasDisplayablePermissions(permCategory: any): boolean {
    const permissions = permCategory.categoryPermissions.map((p: any) => p.hcaPermission);

    return permissions.some((p: any) => isAuthorizedToDisplay(p));
}

const saveUserPermissions = handleSubmit(async () => {
    if (!userProfileId.value)
        return mainStore.setErrorMsg('User Id not found, please refresh page.');

    const permissions: UserPermissions = {
        userId: userProfileId.value,
        permissionsList: [
            ...(addEditAdministrator.value ? [HealthcareAcademyPermissions.ManageAdmins] : []),
            ...(addEditStudent.value ? [HealthcareAcademyPermissions.ManageStudents] : []),
            ...(assignCourses.value ? [HealthcareAcademyPermissions.AllowCourseAssign] : []),
            ...(manageCourseResults.value ? [HealthcareAcademyPermissions.ManageCourseResults] : []),
            ...(importStudents.value ? [HealthcareAcademyPermissions.AllowStudentImport] : []),
            ...(dropCourses.value ? [HealthcareAcademyPermissions.DropCourse] : []),
            ...(competencyEvaluator.value ? [HealthcareAcademyPermissions.ManageCompetencyEvaluator] : []),
            ...(manageAutomatedAssignments.value ? [HealthcareAcademyPermissions.ManageAutomatedAssignments] : []),
            ...(createCustomCompetencies.value ? [HealthcareAcademyPermissions.CreateCustomCompetencies] : []),
        ]
    };

    await authStore.updateUserPermissions(permissions)
        .then(() => mainStore.setSuccessMsg('Successfully updated user permissions.'))
        .catch(() => {});
});

</script>

<template>
    <div class="border-round-lg p-3" style="background-color: var(--hca-form-bg);">
        <form class="flex justify-content-center" @submit="saveUserPermissions">
            <div class="flex flex-column gap-3">

                <Card v-for="permCategory in permissionCategories" :key="permCategory.key" v-show="hasDisplayablePermissions(permCategory)" style="background-color: var(--footer-gray);">
                    <template #title>
                        <div class="flex justify-content-between align-items-end">
                            <span style="color: whitesmoke; font-size: 24px;">{{ permCategory.label }}</span>
                            <span class="pi" :class="permCategory.icon" style="width: 30px; height: 30px;"/>
                        </div>
                    </template>
                    
                    <template #content>
                        <div class="flex flex-wrap gap-4" style="background-color: var(--footer-gray);">
                            <div v-for="perm in permCategory.categoryPermissions" :key="perm.key" v-show="isAuthorizedToDisplay(perm.hcaPermission)">
                                <label :for="perm.inputId" class="flex gap-2 field-checkbox mb-0 p-3 border-round-lg text-200" style="background-color: var(--hca-maroon);" >
                                    <Checkbox :inputId="perm.inputId" v-model="perm.ref" :binary="true" />
                                    <span class="align-content-center"> {{ perm.label }} </span>
                                </label>
                            </div>
                        </div>
                    </template>
                </Card>

                <div class="flex flex-row justify-content-center mt-5">
                    <Button
                        label="Save"
                        type="submit"
                        :loading="isLoading"
                    />
                </div>

            </div>
        </form>
    </div>
</template>
