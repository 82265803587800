import IOrgCourseAccess from '@/models/courses/Course/IOrgCourseAccess';
import IGridFilter from '@/models/search/IGridFilter';
import useAuthStore from '@/store/auth.store';
import useCourseStore from '@/store/course.store';
import UseOrganizationHierarchy from "@/composition-api/useOrganizationHierarchy";
import { FilterMatchMode } from 'primevue/api';
import {DataTableFilterMeta, DataTableSortEvent, DataTablePageEvent} from 'primevue/datatable';
import { Ref, ref, reactive, ComputedRef } from 'vue';
import ISimpleCorporation from '@/models/user/OrganizationLevel/Corporation/ISimpleCorporation';
import ISimpleRegion from '@/models/user/OrganizationLevel/Region/ISimpleRegion';
import ISimpleDivision from '@/models/user/OrganizationLevel/Division/ISimpleDivision';
import ISimpleFacility from '@/models/user/OrganizationLevel/Facility/ISimpleFacility';

export interface ICourseOrgAccessSearch {
    courseIdSearch: Ref<number| undefined>,

    corporations: Ref<ISimpleCorporation[]>,
    corporationId: Ref<number | undefined>,
    isCorporationLoading: Ref<boolean>,

    getRegionByCorp(updatedCorporationId: number | undefined): Promise<void>,
    regions: Ref<ISimpleRegion[]>,
    regionId: Ref<number | undefined>,
    isRegionLoading: Ref<boolean>,
    isRegionDisabled: ComputedRef<boolean>,

    getDivisionsByRegion(updateRegionId: number | undefined): Promise<void>,
    divisions: Ref<ISimpleDivision[]>,
    divisionId: Ref<number | undefined>,
    isDivisionLoading: Ref<boolean>,
    isDivisionDisabled: ComputedRef<boolean>,

    getFacilitiesByDivision(updateDivisionId: number | undefined): Promise<void>,
    facilities: Ref<ISimpleFacility[]>,
    facilityId: Ref<number | undefined>,
    isFacilityLoading: Ref<boolean>,
    isFacilityDisabled: ComputedRef<boolean>,

    courseOrgAccessFilters: Ref<DataTableFilterMeta>,

    courseOrgAccessFromSearch: Ref<IOrgCourseAccess[]>,
    totalRecords: Ref<number>,

    onPage: (event : DataTablePageEvent) => Promise<void>,
    onSort: (event : DataTableSortEvent) => Promise<void>,
    onFilter: (event : IGridFilter) => Promise<void>,
    executeSearch: () => Promise<void>,
}

export default function useCourseOrgAccessSearch(): ICourseOrgAccessSearch {
    const courseStore = useCourseStore();
    const authStore = useAuthStore();
    const {
        corporationId,
        corporations,
        isCorporationLoading,
        getRegionByCorp,

        regionId,
        regions,
        isRegionLoading,
        isRegionDisabled,
        getDivisionsByRegion,

        divisions,
        divisionId,
        isDivisionLoading,
        isDivisionDisabled,
        getFacilitiesByDivision,

        facilities,
        facilityId,
        isFacilityLoading,
        isFacilityDisabled,

    } = UseOrganizationHierarchy();

    const courseIdSearch: Ref<number| undefined> = ref<number| undefined>();

    const courseOrgAccessFromSearch: Ref<IOrgCourseAccess[]> = ref<IOrgCourseAccess[]>([]);
    const totalRecords: Ref<number> = ref<number>(0);

    const courseOrgAccessFilters: Ref<DataTableFilterMeta> = ref<DataTableFilterMeta>({
        courseId : {
            value: courseIdSearch,
            matchMode: FilterMatchMode.EQUALS
        },
        corporation: {
            value: corporationId,
            matchMode: FilterMatchMode.EQUALS
        },
        region: {
            value: regionId,
            matchMode: FilterMatchMode.EQUALS
        },
        division: {
            value: divisionId,
            matchMode: FilterMatchMode.EQUALS
        },
        facility: {
            value: facilityId,
            matchMode: FilterMatchMode.EQUALS
        }
    })

    let courseOrgAccessGridFilter : IGridFilter = reactive({
        filters: courseOrgAccessFilters.value,
        first: 0,
        rows: 10,
        originalEvent: {},
        MultiSortMeta: [],
        page: 0,
        pageCount: 0,
        sortField: '',
        sortOrder: null,
    });

    async function executeSearch() {
        courseOrgAccessGridFilter.requesterId = authStore.getIdentityId;

        try {
            const searchResult = await courseStore.searchOrgForCourseAccess(courseOrgAccessGridFilter)

            totalRecords.value = searchResult.totalRecords;
            courseOrgAccessFromSearch.value = searchResult.organizations;
        } catch (e) {
            totalRecords.value = 0;
            courseOrgAccessFromSearch.value = [];
            console.error(e);
        }
    }

    async function onPage (event : DataTablePageEvent) {
        courseOrgAccessGridFilter.first = event.first;
        courseOrgAccessGridFilter.page = event.page;
        courseOrgAccessGridFilter.rows = event.rows;
        courseOrgAccessGridFilter.originalEvent = event.originalEvent;
        courseOrgAccessGridFilter.pageCount = event.pageCount;

        await executeSearch();
    }

    async function onSort(event : DataTableSortEvent) {
        courseOrgAccessGridFilter.sortField = event.sortField;
        courseOrgAccessGridFilter.sortOrder = event.sortOrder;

        await executeSearch();
    }

    async function onFilter(event : IGridFilter) {
        courseOrgAccessGridFilter = event;

        await executeSearch();
    }

    return {
        courseIdSearch,

        corporations,
        corporationId,
        isCorporationLoading,

        getRegionByCorp,
        regions,
        regionId,
        isRegionLoading,
        isRegionDisabled,

        getDivisionsByRegion,
        divisions,
        divisionId,
        isDivisionLoading,
        isDivisionDisabled,

        getFacilitiesByDivision,
        facilities,
        facilityId,
        isFacilityLoading,
        isFacilityDisabled,

        courseOrgAccessFilters,
        courseOrgAccessFromSearch,
        totalRecords,
        onFilter,
        onPage,
        onSort,
        executeSearch,
    };
}
