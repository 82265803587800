<script setup lang="ts">
import ISimpleCourse from '@/models/courses/Course/ISimpleCourse';
import IRollingCourseAssignment from '@/models/courses/StudentAssignment/Rolling/IRollingCourseAssignment';
import useCourseAssignmentStore from '@/store/courseAssignment.store';
import useMainStore from '@/store/main.store';
import { computed, onMounted, ref, watch } from 'vue';
import router from '@/router';

// eslint-disable-next-line no-undef
const props = defineProps<{
    srcRollingAssignment?: IRollingCourseAssignment | undefined
}>();

const mainStore = useMainStore();
const courseAssignmentStore = useCourseAssignmentStore();

const showDeleteModal = ref<boolean>(false);

const facilityName = ref<string>('');
const departmentName = ref<string>('');
const createdByName = ref<string>('');
const disabledByName = ref<string>('');
const createdOnDate = ref<string>('');
const disabledOnDate = ref<string>('');
const assignByDepartment = ref<boolean>(false);
const course = ref<ISimpleCourse>();
const daysToTarget = ref<number>(0);
const daysToDrop = ref<number>(0);
const newHireBuffer = ref<number>(0);
const isDisabled = ref<boolean>(false);

const rollingAssignment = computed(() => props.srcRollingAssignment);
const isLoading = computed<boolean>(() => mainStore.isBusy);

onMounted(() => {
   initializeValues(); 
});

watch(rollingAssignment, (() => {
    initializeValues();
}));

const initializeValues = () => {
    facilityName.value = rollingAssignment.value?.facility?.name ?? '';
    departmentName.value = rollingAssignment.value?.departmentName ?? '';
    assignByDepartment.value = rollingAssignment.value?.assignByDepartment ?? false;
    course.value = rollingAssignment.value?.course;
    daysToTarget.value = rollingAssignment.value?.daysToTarget ?? 0;
    daysToDrop.value = rollingAssignment.value?.daysToDrop ?? 0;
    newHireBuffer.value = rollingAssignment.value?.newHireBuffer ?? 0;
    isDisabled.value = rollingAssignment.value?.isDisabled ?? false;
    createdByName.value = !rollingAssignment.value?.createdByAdmin ?  '' : `${ rollingAssignment.value.createdByAdmin.firstName } ${ rollingAssignment.value.createdByAdmin.lastName }`;
    disabledByName.value = !rollingAssignment.value?.disabledByAdmin ?  '' : `${ rollingAssignment.value.disabledByAdmin.firstName } ${ rollingAssignment.value.disabledByAdmin.lastName }`;
    createdOnDate.value = (!rollingAssignment.value?.createdOn) ? '' : new Date(rollingAssignment.value.createdOn).toLocaleDateString();
    disabledOnDate.value = !rollingAssignment.value?.disabledDate ? '' : new Date(rollingAssignment.value.disabledDate).toLocaleDateString();
};

async function deleteAssignment() {
    const rollingAssignmentId = rollingAssignment.value?.rollingCourseAssignmentId;

    if (!rollingAssignmentId)
        return mainStore.setErrorMsg('Invalid rolling assignment Id');

    try {
        await courseAssignmentStore.deleteRollingAssignment(rollingAssignmentId);

        showDeleteModal.value = false;
        setTimeout(() => router.push('/automated-course-assignment/rolling'), 2500);

    } catch (e) {
        showDeleteModal.value = false;
        window.scrollTo(0, 0);
    }
}

</script>

<template>
    <div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Facility: </span>
            <span> {{ facilityName }} </span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Assign By Department</span>
            <span> {{ assignByDepartment ? "Yes" : "No" }} </span>
        </div>
        
        <div v-show="assignByDepartment">
            <div class="flex flex-row pt-3" >
                <span class="pr-2">Department: </span>
                <span> {{ departmentName }} </span>
            </div>
        </div>
                
        <div class="flex flex-row pt-3">
            <span class="pr-2">Course: </span>
            <span> {{ course?.name }} </span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Days to Target: </span>
            <span> {{ daysToTarget }} Days</span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Days to Drop: </span>
            <span> {{ daysToDrop }} Days</span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">New Hire Buffer: </span>
            <span> {{ newHireBuffer }} Days</span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Disabled: </span>
            <span> {{ isDisabled ? "Yes" : "No" }} </span>
        </div>
        
        <div class="flex flex-row justify-content-between md:justify-content-end pb-4 pt-6">
            <Button 
                label="Delete" 
                icon="pi pi-check" 
                iconPos="right"
                severity="danger" 
                @click="showDeleteModal = true"
                :loading="isLoading" 
            />
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Created By: </span>
            <span> {{ createdByName }} </span>
            <span class="px-2"> on </span>
            <span> {{ createdOnDate }} </span>
        </div>

        <div v-show="false">
            <div class="flex flex-row pt-3">
                <span class="pr-2">Modified by: </span>
                <span> {{  }} </span>
                <span class="pr-x">on</span>
                <span> {{  }} </span>
            </div>
        </div>

        <div v-show="isDisabled">    
            <div class="flex flex-row pt-3">
                <span class="pr-2">Disabled By: </span>
                <span> {{ disabledByName }} </span>
                <span class="px-2">on</span>
                <span> {{ disabledOnDate }} </span>
            </div>
        </div>

        <Dialog
            v-model:visible="showDeleteModal"
            modal
            header="Are you sure?"
        >
            <div class="mt-3">
                <Button 
                    label="No" 
                    icon="pi pi-times" 
                    class="mr-3" 
                    text 
                    autofocus
                    @click="showDeleteModal = false" 
                />
                <Button 
                    label="Yes" 
                    icon="pi pi-check" 
                    @click="deleteAssignment" 
                />
            </div>
        </Dialog>


    </div>
</template>@/models/courses/Course/ISimpleCourse
