<script setup lang="ts">
import useCourseAssignmentStore from '@/store/courseAssignment.store';
import useAccountStore from '@/store/account.store';
import useMainStore from '@/store/main.store';
import router from '@/router';
import {convertStatusToUserFriendly, getStatusStyle} from '@/helpers/dataTableHelper';
import IStudentAssignment from '@/models/courses/StudentAssignment/IStudentAssignment';
import {computed, onMounted, ref} from 'vue';

const mainStore = useMainStore();
const accountStore = useAccountStore();
const courseAssignmentStore = useCourseAssignmentStore();

accountStore.$onAction(({ after, name }) => {
    after(async () => {
        if (name == 'getUserProfileByUserProfileId')
            await initializeValues()
    })
})

const assignments = ref<IStudentAssignment[]>([]);
const courseToBeDropped = ref<number | undefined>();
const showConfirmDropDialog = ref<boolean>(false);

const isLoading = computed<boolean>(() => mainStore.isBusy);

onMounted(() => initializeValues());

async function initializeValues() {
    const userProfileId = accountStore.getActiveUserProfileId;

    if (!userProfileId)
        return;

    try {
        assignments.value = await courseAssignmentStore.getAssignmentsForStudent(userProfileId);
    } catch (e) {
        assignments.value = [];
        console.error('Error getting student assignments', e);
    }
}

function confirmCourseDrop(courseId: number) {
    if (!courseId)
        return mainStore.setErrorMsg('Invalid student assignment');

    courseToBeDropped.value = courseId;

    showConfirmDropDialog.value = true;
}

async function dropAssignment() {
    const assignmentToBeDropped = assignments.value.find(a => a.courseId == courseToBeDropped.value);

    if (!assignmentToBeDropped)
        return mainStore.setErrorMsg('Invalid student assignment');

    try {
        await courseAssignmentStore.dropAssignment(assignmentToBeDropped.studentId, assignmentToBeDropped.courseId);

        await initializeValues();
    } finally {
        showConfirmDropDialog.value = false;
    }
}

function getCourseName(courseId: number) {
    const course = assignments.value.find(a => a.courseId == courseId)?.course;

    return course?.name ?? '';
}

function clearDropData() {
    courseToBeDropped.value = undefined;
}

</script>

<template>
    <div class="border-round-lg p-3 mb-5" style="background-color: var(--hca-form-bg);">
  
        <DataTable 
            scrollHeight="35rem"
            style="background-color: var(--table-header-gray)"
            lazy
            scrollable
            :value="assignments"
            :loading="isLoading"
            :rows="10"
            :totalRecords="assignments.length"
        >
            <Column header="Course" field="course.name" />
            <Column header="Status" field="status" >
                <template #body="{ data }">
                    <div 
                    class='border-round-lg px-2 py-1 w-full' 
                    :style="{ 
                        backgroundColor: `var(--${ getStatusStyle(data.status) })`,
                        color: `var(--${ getStatusStyle(data.status) }-text-color)`,
                    }"
                >
                    <span class='flex justify-content-center'> {{ convertStatusToUserFriendly(data.status) }} </span>
                </div>
                </template>
            </Column>
            <Column header="Enroll Date" field="enrollDate">
            <template #body="{ data }">
                <div>
                    <span> {{ !data.enrollDate ? '' : new Date(data.enrollDate).toLocaleDateString() }} </span>
                </div>
            </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <div class="flex gap-2">

                        <Button
                            label="Edit"
                            icon="pi pi-pencil"
                            data-ai-id="assignment_editAssignment"
                            @click="router.push({ path: '/course-assignment', query: { studentId: data.studentId, courseId: data.courseId } })"
                        />
                        <Button
                            icon="pi pi-trash"
                            severity="danger"
                            data-ai-id="assignment_promptDropCourse"
                            outlined
                            @click="confirmCourseDrop(data.courseId)"
                        />

                    </div>
                </template>
            </Column>
        </DataTable>

        <Dialog
            v-model:visible="showConfirmDropDialog"
            modal
            header="Are you sure?"
            @hide="clearDropData"
            :draggable="false"
        >
            <div class="flex flex-column gap-2">
                <span>Are you sure you want to drop this course?</span>
                <span class="font-semibold">{{ getCourseName(courseToBeDropped) }}</span>
            </div>

            <div class="flex justify-content-end mt-3">
                <Button
                    label="No"
                    icon="pi pi-times"
                    class="mr-3"
                    data-ai-id="assignment_closeDropCourseDialog"
                    text
                    autofocus
                    @click="showConfirmDropDialog = false"
                />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    data-ai-id="assignment_dropCourse"
                    @click="dropAssignment"
                />
            </div>
        </Dialog>

    </div>
</template>
