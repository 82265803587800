import {defineStore} from "pinia";
import ITrainingCategory from "@/models/courses/trainingCategory";
import ITrainingSubCategory from "@/models/courses/ITrainingSubCategory";
import IGridFilter from "@/models/search/IGridFilter";
import ITrainingCategorySearchResult from "@/models/search/ITrainingCategorySearchResult";
import ITrainingSubCategorySearchResult from "@/models/search/ITrainingSubCategorySearchResult";
import {AxiosError, AxiosResponse} from "axios";
import jwtInterceptor from "@/helpers/http";
import {formatServerErrorResponse} from "@/helpers/jwtHelper";
import useMainStore from "@/store/main.store";

export interface TrainingCategoryStore {
    getAllTrainingCategories(): Promise<ITrainingCategory[]>,
    getTrainingCategory(trainingCategoryId: number) : Promise<ITrainingCategory>,
    getTrainingSubCategoryByTrainingCategoryId(trainingCategoryId: number) : Promise<ITrainingSubCategory[]>,
    searchTrainingCategories(filter: IGridFilter): Promise<ITrainingCategorySearchResult>,
    updateTrainingCategory(trainingCategory: ITrainingCategory): Promise<void>,
    getTrainingSubCategoryById(trainingSubCategoryId: number) : Promise<ITrainingSubCategory>,
    searchTrainingSubCategories(filter: IGridFilter): Promise<ITrainingSubCategorySearchResult>,
    updateTrainingSubCategory(trainingSubCategory: ITrainingSubCategory): Promise<void>,
    getCustomCompetencyTrainingCategories(): Promise<ITrainingCategory[]>,
    createCustomCompetencyTrainingCategory(name: string): Promise<void>,
    getCustomCompetencyTrainingCategoryForAdmin(userProfileId: number): Promise<ITrainingCategory>,
}

const useTrainingCategoryStore = defineStore('trainingCategoryStore', (): TrainingCategoryStore => {
    const mainStore = useMainStore();

    async function getAllTrainingCategories(): Promise<ITrainingCategory[]> {
        const actionName = 'getAllTrainingCategories';
        mainStore.startTask(actionName);

        try {
            const getTrainingCategoriesResponse: AxiosResponse<ITrainingCategory[]> = await jwtInterceptor.get('api/trainingCategory/training-categories');

            return getTrainingCategoriesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Training Categories', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getTrainingCategory(trainingCategoryId: number) : Promise<ITrainingCategory> {
        const actionName = 'getTrainingCategory';
        mainStore.startTask(actionName);

        try {
            const getTrainingCategoryResponse: AxiosResponse<ITrainingCategory> = await jwtInterceptor.get('api/trainingCategory', { params: { id: trainingCategoryId }});

            return getTrainingCategoryResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to get Training Category', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getTrainingSubCategoryByTrainingCategoryId(trainingCategoryId: number) : Promise<ITrainingSubCategory[]> {
        const actionName = 'getTrainingSubCategoryByTrainingCategoryId';
        mainStore.startTask(actionName);

        try {
            const getTrainingSubCategoryResponse: AxiosResponse<ITrainingSubCategory[]> = await jwtInterceptor.get('api/trainingSubCategory/from-training-cat', { params: { id: trainingCategoryId }});

            return getTrainingSubCategoryResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch training sub-categories', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchTrainingCategories(filter: IGridFilter): Promise<ITrainingCategorySearchResult> {
        const actionName = 'searchTrainingCategories';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchTrainingCategoriesResponse: AxiosResponse<ITrainingCategorySearchResult> = await jwtInterceptor.get('api/trainingCategory/search', { params: { filter: btoa(filterJson) }} );

            return searchTrainingCategoriesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to search for training categories', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateTrainingCategory(trainingCategory: ITrainingCategory): Promise<void> {
        const actionName = 'updateTrainingCategory';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/trainingCategory/update', trainingCategory);

            mainStore.setInfoMsg('Successfully Updated Training Category');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Training Category', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getTrainingSubCategoryById(trainingSubCategoryId: number) : Promise<ITrainingSubCategory> {
        const actionName = 'getTrainingSubCategoryById';
        mainStore.startTask(actionName);

        try {
            const getTrainingSubCategoryResponse: AxiosResponse<ITrainingSubCategory> = await jwtInterceptor.get('api/trainingSubCategory', { params: { id: trainingSubCategoryId }});

            return getTrainingSubCategoryResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Training Sub Category', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchTrainingSubCategories(filter: IGridFilter): Promise<ITrainingSubCategorySearchResult> {
        const actionName = 'searchTrainingSubCategories';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchTrainingSubCategoriesResponse: AxiosResponse<ITrainingSubCategorySearchResult> = await jwtInterceptor.get('api/trainingSubCategory/search', { params: { filter: btoa(filterJson) }} );

            return searchTrainingSubCategoriesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to search for Training Sub Categories', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateTrainingSubCategory(trainingSubCategory: ITrainingSubCategory): Promise<void> {
        const actionName = 'updateTrainingSubCategory';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/trainingSubCategory', trainingSubCategory)

            mainStore.setInfoMsg('Successfully Updated Training SubCategory');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update training sub category', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCustomCompetencyTrainingCategories(): Promise<ITrainingCategory[]> {
        const actionName = 'updateTrainingCategory';
        mainStore.startTask(actionName);

        try {
            const response : AxiosResponse<ITrainingCategory[]> = await jwtInterceptor.get('api/trainingCategory/custom-competencies');

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Training Category', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function createCustomCompetencyTrainingCategory(name: string): Promise<void> {
        const actionName = 'createCustomCompetencyTrainingCategory';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/trainingCategory/new-custom-competency', { name });

            mainStore.setInfoMsg('Successfully Created Custom Competency Training Category');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to create Custom Competency Training Category', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCustomCompetencyTrainingCategoryForAdmin(userProfileId: number): Promise<ITrainingCategory> {
        const actionName = 'getCustomCompetencyTrainingCategoryForAdmin';
        mainStore.startTask(actionName);

        try {
            const response : AxiosResponse<ITrainingCategory> = await jwtInterceptor.get('api/trainingCategory/custom-competencies-for-admin', { params: { userProfileId }});

            return response.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to get Custom Competency Training Category', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    return {
        getAllTrainingCategories,
        getTrainingCategory,
        getTrainingSubCategoryByTrainingCategoryId,
        searchTrainingCategories,
        updateTrainingCategory,
        getTrainingSubCategoryById,
        searchTrainingSubCategories,
        updateTrainingSubCategory,
        getCustomCompetencyTrainingCategories,
        createCustomCompetencyTrainingCategory,
        getCustomCompetencyTrainingCategoryForAdmin,
    }
})

export default useTrainingCategoryStore;
