import axios, {AxiosInstance, AxiosResponse} from 'axios';
import useAuthStore from '@/store/auth.store'
import useMainStore from '@/store/main.store'
import { useRouter } from 'vue-router';
import ITokenResult from '@/models/auth/ITokenResult';

const jwtInterceptor: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_APIBASEURL ?? window.location.origin
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

/**
 * Add a callback to the refresh queue
 */
function subscribeTokenRefresh(callback: (token: string) => void): void {
    refreshSubscribers.push(callback);
}

/**
 * Notify all subscribers with the new token
 */
function onRefreshed(token: string): void {
    refreshSubscribers.forEach((callback) => callback(token));
    refreshSubscribers = [];
}

/**
 * Handle token refresh errors
 */
function handleTokenRefreshError(error: unknown): void {
    console.error('Refresh Token Error:', error);
    refreshSubscribers = [];
}

async function refreshToken(): Promise<string> {
    const authStore = useAuthStore();

    if (isRefreshing) {
        // Return a promise for queued requests
        return new Promise((resolve) => {
            subscribeTokenRefresh(resolve);
        });
    }

    isRefreshing = true;

    try {
        const refreshToken: string = authStore.getRefreshToken;
        const accessToken: string = authStore.getUserToken;

        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const response: AxiosResponse<ITokenResult> = await jwtInterceptor.post('api/auth/refresh', { accessToken, refreshToken });
        //if this doesn't work try this:

        const tokenMdl = response.data;

        authStore.saveTokenData(tokenMdl.accessToken, tokenMdl.refreshToken);

        onRefreshed(tokenMdl.accessToken);
        return tokenMdl.accessToken;
    } catch (error) {
        handleTokenRefreshError(error);
        throw error;
    } finally {
        isRefreshing = false;
    }
}

jwtInterceptor.interceptors.request.use(
    (config: any): any => {
        const authStore = useAuthStore();
        const token = authStore.getUserToken;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

jwtInterceptor.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    async (error) => {
        const router = useRouter();
        const authStore = useAuthStore();
        const mainStore = useMainStore();

        const originalRequest = error.config;

        if (
            error.response?.status === 401 &&
            !originalRequest._retry &&
            !isRefreshing
        ) {
            originalRequest._retry = true;

            try {
                const newToken = await refreshToken();

                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return jwtInterceptor(originalRequest);
            } catch (refreshError) {
                console.error('Failed to refresh token:', refreshError);
                mainStore.clearMessages();
                mainStore.setErrorMsg('Sorry your session has expired, you must login again.');
                authStore.$reset();
                await router.push('/');
                return Promise.reject(refreshError);
            }
        }

        // If already refreshing, queue the request
        if (error.response?.status === 401 && !originalRequest._retry) {
            return new Promise((resolve, reject) => {
                subscribeTokenRefresh((token) => {
                    originalRequest._retry = true;
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    resolve(jwtInterceptor(originalRequest));
                });
            });
        }

        return Promise.reject(error);
    }
);

export default jwtInterceptor;
