import { defineStore } from 'pinia';
import { AxiosError, AxiosResponse } from 'axios';
import useMainStore from '@/store/main.store';
import jwtInterceptor from '@/helpers/http';
import ILesson from '@/models/courses/Lesson/ILesson';
import ICourse from '@/models/courses/Course/ICourse';
import IGridFilter from '@/models/search/IGridFilter';
import ICoursePrerequisiteLogic from '@/models/courses/Prerequisite/ICoursePrerequisiteLogic';
import { LogicalOperator } from '@/models/courses/Prerequisite/LogicalOperator';
import ITrainingProvider from '@/models/courses/ITrainingProvider';
import ILessonSearchResult from '@/models/search/ILessonSearchResult';
import ITrainingProviderSearchResult from '@/models/search/ITrainingProviderSearchResult';
import ILessonOther from '@/models/courses/Lesson/ILessonOther';
import ILessonEntry from '@/models/courses/Lesson/ILessonEntry';
import ILessonGeneral from '@/models/courses/Lesson/ILessonGeneral';
import LessonInterface from '@/models/courses/Lesson/LessonInterface';
import IMenuStyle from '@/models/courses/IMenuStyle';
import CourseGeneral from '@/models/courses/Course/CourseGeneral';
import ICourseInterface from '@/models/courses/Course/ICourseInterface';
import ICourseOther from '@/models/courses/Course/ICourseOther';
import IModifyRestrictedCourse from '@/models/courses/Course/IModifyRestrictedCourse';
import ICourseAccessSearchResult from '@/models/search/ICourseAccessSearchResult';
import ICourseGroupAccess from '@/models/courses/Course/ICourseGroupAccess';
import ISimpleCourse from '@/models/courses/Course/ISimpleCourse';
import { formatServerErrorResponse } from '@/helpers/jwtHelper';
import { computed, ComputedRef, Ref, ref } from 'vue';
import ICourseMenuContent from '@/models/courses/Course/ICourseMenuContent';
import CourseSearchResult from '@/models/search/courses/CourseSearchResult';
import LessonObjective from '@/models/student/evaluation/LessonObjective';

export interface ICourseStore {
    getActiveLesson: ComputedRef<ILesson | undefined>,
    getActiveLessonId: ComputedRef<number | undefined>,
    getActiveLessonName: ComputedRef<string | undefined>,
    getActiveCourse: ComputedRef<ICourse | undefined>,
    getActiveCourseId: ComputedRef<number | undefined>,
    getActiveCourseName: ComputedRef<string | undefined>,

    getCourse(courseId: number): Promise<ICourse>,
    searchCourses(filter: IGridFilter) : Promise<CourseSearchResult>,
    updateCourseGeneral(course: CourseGeneral): Promise<void>,
    updateCourseInterface(course: ICourseInterface): Promise<void>,
    updateCourseOther(course: ICourseOther): Promise<void>,
    getMenuContent(courseId: number): Promise<ICourseMenuContent>,
    updateCourseContent(updateMdl: ICourseMenuContent): Promise<void>,
    updateCourseGroupAccess(course: ICourseGroupAccess): Promise<void>,
    searchOrgForCourseAccess(filter: IGridFilter): Promise<ICourseAccessSearchResult>,
    revokeCourseAccess(editAccess: IModifyRestrictedCourse): Promise<void>,
    enableCourseAccess(editAccess: IModifyRestrictedCourse): Promise<void>,
    getCoursePreRequisites(courseId: number): Promise<ICoursePrerequisiteLogic>,
    updatePrerequisites(prereqLogic: ICoursePrerequisiteLogic): Promise<void>,
    updateGroupLogicalOperator(courseId: number, newGroupLogicalOperator: LogicalOperator): Promise<void>,
    getLesson(lessonId: number): Promise<ILesson>,
    getLessonObjectives(lessonId: number): Promise<LessonObjective[]>,
    searchLessons(filter: IGridFilter): Promise<ILessonSearchResult>,
    updateLessonGeneralInfo(lesson: ILessonGeneral): Promise<void>,
    updateLessonEntry(lesson: ILessonEntry): Promise<void>,
    updateLessonInterface(lesson: LessonInterface): Promise<void>,
    updateLessonOther(lesson: ILessonOther): Promise<void>,
    getAllMenuStyles() : Promise<IMenuStyle[]>,
    getMenuStyleById(menuStyleId: number) : Promise<IMenuStyle>,
    updateMenuStyle(menuStyle: IMenuStyle): Promise<void>,
    getAllTrainingProviders() : Promise<ITrainingProvider[]>,
    getTrainingProvider(trainingProviderId: number): Promise<ITrainingProvider>,
    searchTrainingProvider(filter: IGridFilter): Promise<ITrainingProviderSearchResult>,
    updateTrainingProvider(trainingProvider: ITrainingProvider): Promise<void>,
    getAvailableCourses(facilityId: number, departmentId: number | undefined, studentId: number | undefined): Promise<ISimpleCourse[]>,
    clearActiveCourse(): void,
    clearActiveLesson(): void
}

const useCourseStore = defineStore('courseStore', (): ICourseStore =>  {
    const mainStore = useMainStore();

    const activeLesson: Ref<ILesson | undefined> = ref<ILesson | undefined>();
    const activeCourse: Ref<ICourse | undefined> = ref<ICourse | undefined>();

    const getActiveLesson: ComputedRef<ILesson | undefined> = computed<ILesson | undefined>(() => activeLesson.value);
    const getActiveLessonId: ComputedRef<number | undefined> = computed<number | undefined>(() => activeLesson.value?.lessonId);
    const getActiveLessonName: ComputedRef<string | undefined> = computed<string | undefined>(() => activeLesson.value?.name);
    const getActiveCourse: ComputedRef<ICourse | undefined> = computed<ICourse | undefined>(() => activeCourse.value);
    const getActiveCourseId: ComputedRef<number | undefined> = computed<number | undefined>(() => activeCourse.value?.courseId);
    const getActiveCourseName: ComputedRef<string | undefined> = computed<string | undefined>(() => activeCourse.value?.name);


    async function getCourse(courseId: number): Promise<ICourse> {
        const actionName = 'getCourse';
        mainStore.startTask(actionName);

        try {
            const getCourseResponse: AxiosResponse<ICourse> = await jwtInterceptor.get('api/course', { params: { courseId } });

             activeCourse.value = getCourseResponse.data;
             return getCourseResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Course. Please try again later.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchCourses(filter: IGridFilter) : Promise<CourseSearchResult> {
        const actionName = 'searchCourses';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchCoursesResponse: AxiosResponse<CourseSearchResult> = await jwtInterceptor.get('api/course/search', { params: { filter: btoa(filterJson) }} );

            return searchCoursesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to search Courses.', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCourseGeneral(course: CourseGeneral): Promise<void> {
        const actionName = 'updateCourseGeneral';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/update-general', course);

            mainStore.setInfoMsg('Successfully Updated Course General Info');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course General Info', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCourseInterface(course: ICourseInterface): Promise<void> {
        const actionName = 'updateCourseInterface';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/update-interface', course);

            mainStore.setInfoMsg('Successfully Updated Course Interface');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course Interface', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getMenuContent(courseId: number): Promise<ICourseMenuContent> {
        const actionName = 'getMenuContent';
        mainStore.startTask(actionName);

        try {
            const getMenuContentResponse: AxiosResponse<ICourseMenuContent> = await jwtInterceptor.get('api/course/menu-content', { params: { courseId } });

            return getMenuContentResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch course menu content', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCourseContent(updateMdl: ICourseMenuContent): Promise<void> {
        const actionName = 'updateCourseContent';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/update-content', updateMdl);

            mainStore.setInfoMsg('Successfully Updated Course Content');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course Content', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCourseOther(course: ICourseOther): Promise<void> {
        const actionName = 'updateCourseOther';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/update-other', course);

            mainStore.setInfoMsg('Successfully Updated Course Other');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course Other', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateCourseGroupAccess(course: ICourseGroupAccess): Promise<void> {
        const actionName = 'updateCourseGroupAccess';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/update-group-access', course);

            mainStore.setInfoMsg('Successfully Updated Course Access');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Course Access', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchOrgForCourseAccess(filter: IGridFilter): Promise<ICourseAccessSearchResult> {
        const actionName = 'searchOrgForCourseAccess';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchOrgForCourseAccessResponse: AxiosResponse<ICourseAccessSearchResult> = await jwtInterceptor.get('api/course/search-org-access', { params: { filter: btoa(filterJson) }} );

            return searchOrgForCourseAccessResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to Search Course Organization Access', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function revokeCourseAccess(editAccess: IModifyRestrictedCourse): Promise<void> {
        const actionName = 'revokeCourseAccess';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/disable-access', editAccess);

            mainStore.setInfoMsg('Successfully removed access to course');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to revoke course access.', error);

            mainStore.setErrorMsg(errorMsg);
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function enableCourseAccess(editAccess: IModifyRestrictedCourse): Promise<void> {
        const actionName = 'enableCourseAccess';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/course/enable-access', editAccess);

            mainStore.setInfoMsg('Successfully enabled access to course');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to grant course access.', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getCoursePreRequisites(courseId: number): Promise<ICoursePrerequisiteLogic> {
        const actionName = 'getCoursePreRequisites';
        mainStore.startTask(actionName);

        try {
            const getCoursePreRequisitesResponse: AxiosResponse<ICoursePrerequisiteLogic> = await jwtInterceptor.get('api/CoursePrerequisite', { params: { courseId } });

            return getCoursePreRequisitesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch course prerequisites', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updatePrerequisites(prereqLogic: ICoursePrerequisiteLogic): Promise<void> {
        const actionName = 'updatePrerequisites';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/coursePrerequisite/update', prereqLogic);

            mainStore.setInfoMsg('Successfully Updated Course Prerequisites');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update course prerequisites', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateGroupLogicalOperator(courseId: number, newGroupLogicalOperator: LogicalOperator): Promise<void> {
        const actionName = 'updateGroupLogicalOperator';
        mainStore.startTask(actionName);

        const mdl = {
            courseId,
            operator: newGroupLogicalOperator
        };

        try {
            await jwtInterceptor.post('api/coursePrerequisite/update-group-operator', mdl);

            mainStore.setInfoMsg('Successfully Updated Course Prerequisites');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update course prerequisite group operator (AND, OR, Min of)', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getLesson(lessonId: number): Promise<ILesson> {
        const actionName = 'getLesson';
        mainStore.startTask(actionName);

        try {
            const getLessonResponse: AxiosResponse<ILesson> = await jwtInterceptor.get('api/lesson', {params: { id: lessonId }});

            activeLesson.value = getLessonResponse.data;
            return getLessonResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Lesson', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getLessonObjectives(lessonId: number): Promise<LessonObjective[]> {
        const actionName = 'getLessonObjectives';
        mainStore.startTask(actionName);

        try {
            const getLessonObjectivesResponse: AxiosResponse<LessonObjective[]> = await jwtInterceptor.get('api/lesson/lesson-objectives', {params: { lessonId }});

            return getLessonObjectivesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch lesson objectives', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchLessons(filter: IGridFilter): Promise<ILessonSearchResult> {
        const actionName = 'searchLessons';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchLessonsResponse: AxiosResponse<ILessonSearchResult> = await jwtInterceptor.get('api/lesson/search', { params: { filter: btoa(filterJson) }} );

            return searchLessonsResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return Lesson search results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateLessonGeneralInfo(lesson: ILessonGeneral): Promise<void> {
        const actionName = 'updateLessonGeneralInfo';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/lesson/update-general', lesson);

            mainStore.setInfoMsg('Successfully Updated Lesson General Info');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Lesson General Info', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateLessonEntry(lesson: ILessonEntry): Promise<void> {
        const actionName = 'updateLessonEntry';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/lesson/update-entry', lesson);

            mainStore.setInfoMsg('Successfully Updated Lesson Entry');
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Lesson Entry', error);

            mainStore.setErrorMsg(errorMsg);
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateLessonInterface(lesson: LessonInterface): Promise<void> {
        const actionName = 'updateLessonInterface';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/lesson/update-interface', lesson);

            mainStore.setInfoMsg('Successfully Updated Lesson Interface');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Lesson Interface', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateLessonOther(lesson: ILessonOther): Promise<void> {
        const actionName = 'updateLessonOther';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/lesson/update-other', lesson);

            mainStore.setInfoMsg('Successfully Updated Lesson Other');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Lesson Other', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAllMenuStyles() : Promise<IMenuStyle[]> {
        const actionName = 'getAllMenuStyles';
        mainStore.startTask(actionName);

        try {
            const getAllMenuStylesResponse: AxiosResponse<IMenuStyle[]> = await jwtInterceptor.get('api/MenuStyle/get-all');

            return getAllMenuStylesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch all Menu Styles', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getMenuStyleById(menuStyleId: number) : Promise<IMenuStyle> {
        const actionName = 'getMenuStyleById';
        mainStore.startTask(actionName);

        try {
            const getMenuStyleByIdResponse: AxiosResponse<IMenuStyle> = await jwtInterceptor.get('api/MenuStyle', { params: { id: menuStyleId }});

            return getMenuStyleByIdResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Menu Style', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateMenuStyle(menuStyle: IMenuStyle): Promise<void> {
        const actionName = 'updateMenuStyle';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/menuStyle/update', menuStyle);

            mainStore.setInfoMsg('Successfully Updated Menu Style');
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to get Menu Style', error);

            mainStore.setErrorMsg(errorMsg);
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAllTrainingProviders() : Promise<ITrainingProvider[]> {
        const actionName = 'getAllTrainingProviders';
        mainStore.startTask(actionName);

        try {
            const getAllTrainingProvidersResponse: AxiosResponse<ITrainingProvider[]> = await jwtInterceptor.get('api/trainingProvider/training-providers');

            return getAllTrainingProvidersResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch all Training Providers', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getTrainingProvider(trainingProviderId: number): Promise<ITrainingProvider> {
        const actionName = 'getTrainingProvider';
        mainStore.startTask(actionName);

        try {
            const getTrainingProviderResponse: AxiosResponse<ITrainingProvider> = await jwtInterceptor.get('api/trainingProvider', {params: { id: trainingProviderId }});

            return getTrainingProviderResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch Training Provider', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function searchTrainingProvider(filter: IGridFilter): Promise<ITrainingProviderSearchResult> {
        const actionName = 'searchTrainingProvider';
        mainStore.startTask(actionName);

        const filterJson = JSON.stringify(filter);

        try {
            const searchTrainingProviderResponse: AxiosResponse<ITrainingProviderSearchResult> = await jwtInterceptor.get('api/trainingProvider/search', { params: { filter: btoa(filterJson) }} );

            return searchTrainingProviderResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to return Training Provider Search Results', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function updateTrainingProvider(trainingProvider: ITrainingProvider): Promise<void> {
        const actionName = 'updateTrainingProvider';
        mainStore.startTask(actionName);

        try {
            await jwtInterceptor.post('api/trainingProvider/update', trainingProvider);

            mainStore.setInfoMsg('Successfully Updated Training Provider');
        }
        catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to update Training Provider', error);

            mainStore.setErrorMsg(errorMsg);
        }
        finally {
            mainStore.taskCompleted(actionName);
        }
    }

    async function getAvailableCourses(facilityId: number, departmentId: number | undefined, studentId: number | undefined): Promise<ISimpleCourse[]> {
        const actionName = 'getAvailableCourses';
        mainStore.startTask(actionName);

        try {
            const getAvailableCoursesResponse: AxiosResponse<ISimpleCourse[]> = await jwtInterceptor.get('api/course/available-courses', {
                params: {
                    facilityId,
                    departmentId,
                    studentId
                }
            });

            return getAvailableCoursesResponse.data;
        } catch (error: AxiosError | any) {
            const errorMsg = await formatServerErrorResponse('Unable to fetch available Courses', error);

            mainStore.setErrorMsg(errorMsg);
            throw errorMsg;
        } finally {
            mainStore.taskCompleted(actionName);
        }
    }

    function clearActiveCourse(): void {
        activeCourse.value = undefined;
    }

    function clearActiveLesson(): void {
        activeLesson.value = undefined;
    }

    return {
        getActiveLesson,
        getActiveLessonId,
        getActiveLessonName,
        getActiveCourse,
        getActiveCourseId,
        getActiveCourseName,

        getCourse,
        searchCourses,
        updateCourseGeneral,
        updateCourseInterface,
        updateCourseOther,
        getMenuContent,
        updateCourseContent,
        updateCourseGroupAccess,
        searchOrgForCourseAccess,
        revokeCourseAccess,
        enableCourseAccess,
        getCoursePreRequisites,
        updatePrerequisites,
        updateGroupLogicalOperator,
        getLesson,
        getLessonObjectives,
        searchLessons,
        updateLessonGeneralInfo,
        updateLessonEntry,
        updateLessonInterface,
        updateLessonOther,
        getAllMenuStyles,
        getMenuStyleById,
        updateMenuStyle,
        getAllTrainingProviders,
        getTrainingProvider,
        searchTrainingProvider,
        updateTrainingProvider,
        getAvailableCourses,
        clearActiveCourse,
        clearActiveLesson
    }
})

export default useCourseStore;
