<script setup lang="ts">
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import useAccountStore from '@/store/account.store';
import usePasswordValidation from '@/composition-api/usePasswordValidation';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from "zod";
import {computed} from "vue";

const authStore = useAuthStore();
const mainStore = useMainStore();
const accountStore = useAccountStore();
const passwordValidation = usePasswordValidation();

const { handleSubmit, errors } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            newPassword: zod.string({ required_error: 'Required' }).min(6, 'Password must be at least 6 characters'),
            confirmPassword: zod.string()
        }).superRefine(({ newPassword, confirmPassword }, ctx) => {

            if (!passwordValidation.doesPasswordMeetNumericReq(newPassword)) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    path: ['newPassword'],
                    message: "Password must contain number",
                });
            }

            if (!passwordValidation.doesPasswordMeetLowercaseReq(newPassword)) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    path: ['newPassword'],
                    message: "Password must contain one lowercase character",
                });
            }

            if (!passwordValidation.doesPasswordMeetUppercaseReq(newPassword)) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    path: ['newPassword'],
                    message: "Password must contain one uppercase character",
                });
            }

            if (!passwordValidation.doesPasswordMeetNonAlphaNumericReq(newPassword)) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    path: ['newPassword'],
                    message: "Password must contain one non-alphanumeric character",
                });
            }

            if (newPassword !== confirmPassword) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    path: ['confirmPassword'],
                    message: "Passwords don't match"
                });
            }
        })
    ),
});

const { value: newPassword } = useField<string>('newPassword');
const { value: confirmPassword } = useField<string>('confirmPassword');

const isLoading = computed<boolean>(() => mainStore.isBusy);

function clearInputs() {
    newPassword.value = '';
    confirmPassword.value = '';
}

const submitUpdatePassword = handleSubmit(async (data) => {
    const userProfileId = accountStore.getActiveUserProfileId;

    if (!userProfileId)
        return mainStore.setErrorMsg('User Id not found, please refresh page.');

    const { newPassword, confirmPassword } = data;

    return await authStore.adminResetPassword(userProfileId, newPassword, confirmPassword)
        .then(() => clearInputs())
        .catch();
});

</script>

<template>
    <div class="border-round-lg p-3" style="background-color: var(--hca-form-bg);">

        <form class="flex justify-content-center" @submit="submitUpdatePassword">
            <div class="flex flex-column mt-5 gap-5">

                <div>
                    <FloatLabel>
                        <Password
                            v-model="newPassword"
                            id="newPassword"
                            toggleMask
                            :invalid="!!errors.newPassword"
                        >
                            <template #footer>
                                <Divider />
                                <div class="mt-3">

                                    <div class="flex flex-row gap-2">
                                        <i class="pi pi-check color-success" v-show="passwordValidation.doesPasswordMeetNumericReq(newPassword)"/>
                                        <i class="pi pi-times color-failure" v-show="!passwordValidation.doesPasswordMeetNumericReq(newPassword)"/>
                                        <span>One number</span>
                                    </div>

                                    <div class="flex flex-row gap-2">
                                        <i class="pi pi-check color-success" v-show="passwordValidation.doesPasswordMeetLowercaseReq(newPassword)"/>
                                        <i class="pi pi-times color-failure" v-show="!passwordValidation.doesPasswordMeetLowercaseReq(newPassword)"/>
                                        <span>One lowercase character</span>
                                    </div>

                                    <div class="flex flex-row gap-2">
                                        <i class="pi pi-check color-success" v-show="passwordValidation.doesPasswordMeetUppercaseReq(newPassword)"/>
                                        <i class="pi pi-times color-failure" v-show="!passwordValidation.doesPasswordMeetUppercaseReq(newPassword)"/>
                                        <span>One uppercase character</span>
                                    </div>

                                    <div class="flex flex-row gap-2">
                                        <i class="pi pi-check color-success" v-show="passwordValidation.doesPasswordMeetNonAlphaNumericReq(newPassword)"/>
                                        <i class="pi pi-times color-failure" v-show="!passwordValidation.doesPasswordMeetNonAlphaNumericReq(newPassword)"/>
                                        <span>One special character</span>
                                    </div>

                                    <div class="flex flex-row gap-2">
                                        <i class="pi pi-check color-success" v-show="passwordValidation.doesPasswordMeetLengthReq(newPassword)"/>
                                        <i class="pi pi-times color-failure" v-show="!passwordValidation.doesPasswordMeetLengthReq(newPassword)"/>
                                        <span>6 characters minimum</span>
                                    </div>

                                </div>
                            </template>
                        </Password>
                        <label for="newPassword">New Password</label>
                    </FloatLabel>
                    <small class="p-error"> {{ errors["newPassword"] }}</small>
                </div>

                <div>
                   <FloatLabel>
                        <Password
                            v-model="confirmPassword"
                            id="confirmPassword"
                            class="w-full"
                            toggleMask
                            :invalid="!!errors.confirmPassword"
                        />
                        <label for="confirmPassword">Confirm Password</label>
                   </FloatLabel>
                    <small class="p-error">{{ errors["confirmPassword"] }}</small>
                </div>

                <div class="flex justify-content-center">
                    <Button
                        label="Save"
                        type="submit"
                        :loading="isLoading"
                    />
                </div>

            </div>
        </form>

    </div>
</template>
