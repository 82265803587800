import {computed, ComputedRef, onMounted, Ref, ref, watch } from 'vue';
import ISimpleCorporation from '@/models/user/OrganizationLevel/Corporation/ISimpleCorporation';
import ISimpleRegion from '@/models/user/OrganizationLevel/Region/ISimpleRegion';
import ISimpleDivision from '@/models/user/OrganizationLevel/Division/ISimpleDivision';
import ISimpleFacility from '@/models/user/OrganizationLevel/Facility/ISimpleFacility';
import ISimpleDepartment from '@/models/user/OrganizationLevel/Department/ISimpleDepartment';
import IOrganizationHierarchy from '@/models/user/OrganizationLevel/IOrganizationHierarchy';
import AuthorizationLevel from '@/models/auth/AuthorizationLevel';
import IOrgWideDeptSearchIds from '@/models/search/Organizations/IOrgWideDeptSearchIds';
import AdminOrganizationAccess from "@/models/user/OrganizationLevel/AdminOrganizationAccess";
import useOrganizationStore from '@/store/organization.store';
import useAuthStore from '@/store/auth.store';
import useAccountStore from '@/store/account.store';
import useMainStore from '@/store/main.store';

export interface IUseOrganizationHierarchy{
    corporations: Ref<ISimpleCorporation[]>,
    corporationId: Ref<number | undefined>,
    isCorporationLoading: Ref<boolean>,
    shouldShowCorporation: ComputedRef<boolean>,
    getCorporations(): Promise<void>,

    regions: Ref<ISimpleRegion[]>,
    regionId: Ref<number | undefined>,
    isRegionLoading: Ref<boolean>,
    isRegionDisabled: ComputedRef<boolean>,
    shouldShowRegion: ComputedRef<boolean>,
    getRegionByCorp(updatedCorporationId: number | undefined): Promise<void>,

    divisions: Ref<ISimpleDivision[]>,
    divisionId: Ref<number | undefined>,
    isDivisionLoading: Ref<boolean>,
    isDivisionDisabled: ComputedRef<boolean>,
    shouldShowDivision: ComputedRef<boolean>,
    getDivisionsByRegion(updateRegionId: number | undefined): Promise<void>,

    facilities: Ref<ISimpleFacility[]>,
    facilityId: Ref<number | undefined>,
    isFacilityLoading: Ref<boolean>,
    isFacilityDisabled: ComputedRef<boolean>,
    shouldShowFacility: ComputedRef<boolean>,
    getFacilitiesByDivision(updateDivisionId: number | undefined): Promise<void>,
    getAllAvailableFacilities(): Promise<void>,

    departments: Ref<ISimpleDepartment[]>,
    departmentId: Ref<number | undefined>,
    isDepartmentLoading: Ref<boolean>,
    isDepartmentDisabled: ComputedRef<boolean>,
    shouldShowDepartment: ComputedRef<boolean>,
    getDepartmentsByFacility(updateFacilityId: number | undefined): Promise<void>,

    orgWideDeptName: Ref<string | undefined>,
    orgWideDepartments: Ref<string[]>,
    isOrgWideDepartmentDisabled: ComputedRef<boolean>,
    isOrgWideDepartmentLoading: Ref<boolean>,

    initializationComplete: Ref<boolean>,
    manualInitialization: Ref<boolean>,
}

function useOrganizationHierarchy(): IUseOrganizationHierarchy {

    const mainStore = useMainStore();
    const authStore= useAuthStore();
    const accountStore = useAccountStore();
    const organizationStore = useOrganizationStore();

   const adminUserHierarchy: Ref<IOrganizationHierarchy | undefined> = ref<IOrganizationHierarchy>();
   const additionalAuthorizedOrgs: Ref<AdminOrganizationAccess> = ref<AdminOrganizationAccess>({
       corporations: [],
       regions: [],
       divisions: [],
       facilities: [],
       departments: []
   });

    const corporations: Ref<ISimpleCorporation[]> = ref<ISimpleCorporation[]>([]);
    const regions: Ref<ISimpleRegion[]> = ref<ISimpleRegion[]>([]);
    const divisions: Ref<ISimpleDivision[]> = ref<ISimpleDivision[]>([]);
    const facilities: Ref<ISimpleFacility[]> = ref<ISimpleFacility[]>([]);
    const departments: Ref<ISimpleDepartment[]> = ref<ISimpleDepartment[]>([]);
    const orgWideDepartments: Ref<string[]> = ref<string[]>([]);

    const corporationId: Ref<number | undefined> = ref<number | undefined>();
    const regionId: Ref<number | undefined> = ref<number | undefined>();
    const divisionId: Ref<number | undefined> = ref<number | undefined>();
    const facilityId: Ref<number | undefined> = ref<number | undefined>();
    const departmentId: Ref<number | undefined> = ref<number | undefined>();
    const orgWideDeptName: Ref<string | undefined> = ref<string | undefined>();

    const isCorporationLoading: Ref<boolean> = ref<boolean>(false);
    const isRegionLoading: Ref<boolean> = ref<boolean>(false);
    const isDivisionLoading: Ref<boolean> = ref<boolean>(false);
    const isFacilityLoading: Ref<boolean> = ref<boolean>(false);
    const isDepartmentLoading: Ref<boolean> = ref<boolean>(false);
    const isOrgWideDepartmentLoading: Ref<boolean> = ref<boolean>(false);

    const adminLevel: ComputedRef<AuthorizationLevel> = computed<AuthorizationLevel>(() => authStore.getAuthorizationLevel);
    const shouldShowCorporation: ComputedRef<boolean> = computed<boolean>(() => adminLevel.value >= AuthorizationLevel.CorporationAdministrator);
    const shouldShowRegion: ComputedRef<boolean> = computed<boolean>(() => adminLevel.value >= AuthorizationLevel.RegionAdministrator);
    const shouldShowDivision: ComputedRef<boolean> = computed<boolean>(() => adminLevel.value >= AuthorizationLevel.DivisionAdministrator);
    const shouldShowFacility: ComputedRef<boolean> = computed<boolean>(() => adminLevel.value >= AuthorizationLevel.FacilityAdministrator);
    const shouldShowDepartment: ComputedRef<boolean> = computed<boolean>(() => adminLevel.value >= AuthorizationLevel.DepartmentAdministrator);

    const isRegionDisabled: ComputedRef<boolean> = computed<boolean>(() => (shouldShowCorporation.value && !corporationId.value) || !regions.value);
    const isDivisionDisabled: ComputedRef<boolean> = computed<boolean>(() => (shouldShowRegion.value && !regionId.value) || !divisions.value);
    const isFacilityDisabled: ComputedRef<boolean> = computed<boolean>(() => (shouldShowDivision.value && !divisionId.value) || !facilities.value);
    const isDepartmentDisabled: ComputedRef<boolean> = computed<boolean>(() => (shouldShowFacility.value && !facilityId.value) || !departments.value);
    const isOrgWideDepartmentDisabled: ComputedRef<boolean> = computed<boolean>(() => !orgWideDepartments.value);

    const initializationComplete: Ref<boolean> = ref<boolean>(false);
    const manualInitialization: Ref<boolean> = ref<boolean>(false);

    watch(regions, (newRegionList: ISimpleRegion[], oldRegionList: ISimpleRegion[]): void => {
        const areEqual = JSON.stringify(newRegionList) === JSON.stringify(oldRegionList);

        if (areEqual)
            return;

        resetOrgIds('region');
    })

    watch(divisions, (newDivisionList: ISimpleDivision[], oldDivisionList: ISimpleDivision[]): void => {
        const areEqual = JSON.stringify(newDivisionList) === JSON.stringify(oldDivisionList);

        if (areEqual)
            return;

        resetOrgIds('division');
    })

    watch(facilities, (newFacilityList: ISimpleFacility[], oldFacilityList: ISimpleFacility[]): void => {
        const areEqual = JSON.stringify(newFacilityList) === JSON.stringify(oldFacilityList);

        if (areEqual)
            return;

        resetOrgIds('facility')
    })

    watch(departments, (newDeptList: ISimpleDepartment[], oldDeptList: ISimpleDepartment[]): void => {
        const areEqual = JSON.stringify(newDeptList) === JSON.stringify(oldDeptList);

        if (areEqual)
            return;

        resetOrgIds('department');
    })

    onMounted(async(): Promise<void> => {

        try {
            adminUserHierarchy.value = await accountStore.getAdminHierarchy(authStore.getIdentityId);
        }
        catch {
            adminUserHierarchy.value = undefined;
        }

        try {
            const userProfileId = authStore.getUserProfileId;

            if (!userProfileId)
                return mainStore.setErrorMsg('User Profile Id not found');

            additionalAuthorizedOrgs.value = await accountStore.getAllAdminOrganizationAccess(userProfileId);
        }
        catch {
            additionalAuthorizedOrgs.value = {
                corporations: [],
                regions: [],
                divisions: [],
                facilities: [],
                departments: []
            }
        }

        await initialize();

        initializationComplete.value = true;
    });

    async function initialize(): Promise<void> {
        if (manualInitialization.value)
            return;

        try {
            if (adminLevel.value === AuthorizationLevel.SystemAdministrator)
                return await getCorporations();
        }
        catch {
            corporations.value = [];
            return;
        }

        try {

            const adminCorporation: ISimpleCorporation | undefined = adminUserHierarchy.value?.corporation;

            if (!adminCorporation) {
                return mainStore.setErrorMsg('Admin not assigned to a corporation');
            }

            corporations.value = [adminCorporation, ...additionalAuthorizedOrgs.value.corporations];

            if (adminLevel.value === AuthorizationLevel.CorporationAdministrator) {
                await getRegionByCorp(adminCorporation.corporationId);
                return;
            }

            const adminRegion: ISimpleRegion | undefined = adminUserHierarchy.value?.region;

            if (!adminRegion)
                return mainStore.setErrorMsg('Admin not assigned to a region');

            regions.value = [adminRegion, ...additionalAuthorizedOrgs.value.regions];

            if (adminLevel.value === AuthorizationLevel.RegionAdministrator) {
                await getDivisionsByRegion(adminRegion.regionId);
                return;
            }

            const adminDivision: ISimpleDivision | undefined = adminUserHierarchy.value?.division;

            if (!adminDivision)
                return mainStore.setErrorMsg('Admin not assigned to a division');

            divisions.value = [adminDivision, ...additionalAuthorizedOrgs.value.divisions];

            if (adminLevel.value === AuthorizationLevel.DivisionAdministrator) {
                await getFacilitiesByDivision(adminDivision.divisionId);
                return ;
            }

            const adminFacility: ISimpleFacility | undefined = adminUserHierarchy.value?.facility;

            if (!adminFacility)
                return mainStore.setErrorMsg('Admin not assigned to a facility');

            facilities.value = [adminFacility, ...additionalAuthorizedOrgs.value.facilities];

            await getDepartmentsByFacility(adminFacility.facilityId);
        }
        catch {
            mainStore.setErrorMsg('Unable to set organization hierarchy');
        }
    }

    async function getCorporations(): Promise<void> {
        try {
            isCorporationLoading.value = true;
            corporations.value = await organizationStore.getAllCorporations();
        }
        finally {
            isCorporationLoading.value = false;
        }
    }

    async function getRegionByCorp(updatedCorporationId: number | undefined): Promise<void> {
        const oldCorporationId: number = regions.value[0]?.corporationId;
        if (oldCorporationId === updatedCorporationId)
            return;

        if (!updatedCorporationId) {
            clearOrganizationLevels('corporation');
            return;
        }

        try {
            isRegionLoading.value = true;

            const corporationRegions = await organizationStore.getRegionsByCorporation(updatedCorporationId);
            regions.value = [...corporationRegions, ...additionalAuthorizedOrgs.value.regions];
        }
        catch {
            regions.value = [];
            throw new Error('Unable to get regions by corporation');
        }
        finally {
            isRegionLoading.value = false;
        }

        clearOrganizationLevels('region');

        await searchOrganizationForMatchingDepts(updatedCorporationId);
   }

    async function getDivisionsByRegion(updateRegionId: number | undefined): Promise<void> {

        const oldRegionId: number = divisions.value[0]?.regionId;
        if (oldRegionId === updateRegionId)
            return;

        if (!updateRegionId) {
            clearOrganizationLevels('region');
            return;
        }

        try {
            isDivisionLoading.value = true;

            const regionDivision = await organizationStore.getDivisionsByRegion(updateRegionId);
            divisions.value = [...regionDivision, ...additionalAuthorizedOrgs.value.divisions];
        }
        catch {
            divisions.value = [];
            throw new Error('Unable to get divisions by region');
        }
        finally {
            isDivisionLoading.value = false;
        }

        clearOrganizationLevels('division');

        await searchOrganizationForMatchingDepts(undefined, updateRegionId);
    }

    async function getFacilitiesByDivision(updateDivisionId: number | undefined): Promise<void> {

        const oldDivisionId: number = facilities.value[0]?.divisionId;
        if (oldDivisionId === updateDivisionId)
            return;

        if (!updateDivisionId) {
            clearOrganizationLevels('division')
            return;
        }

        try {
            isFacilityLoading.value = true;

            const divisionFacilities = await organizationStore.getFacilitiesByDivision(updateDivisionId);
            facilities.value = [...divisionFacilities, ...additionalAuthorizedOrgs.value.facilities];
        }
        catch {
            facilities.value = [];
            throw new Error('Unable to get facilities by division');
        }
        finally {
            isFacilityLoading.value = false;
        }

        clearOrganizationLevels('facility');

        await searchOrganizationForMatchingDepts(undefined, undefined, updateDivisionId);
    }

    async function getAllAvailableFacilities(): Promise<void> {
        const identityId = authStore.getIdentityId;

        if (!identityId)
            return mainStore.setErrorMsg('Identity Id not found');

        try {
            isFacilityLoading.value = true;

            facilities.value = await organizationStore.getAllFacilities(identityId);
        }
        catch {
            facilities.value = [];
            throw new Error('Unable to get all facilities');
        }
        finally {
            isFacilityLoading.value = false;
        }

        clearOrganizationLevels('facility');
    }

    async function getDepartmentsByFacility(updateFacilityId: number | undefined): Promise<void> {

        const oldFacilityId: number = departments.value[0]?.facilityId;
        if (oldFacilityId === updateFacilityId)
            return;

        if (!updateFacilityId) {
            clearOrganizationLevels('facility');
            return;
        }

        try {
            isDepartmentLoading.value = true;

            const facilityDepartments = await organizationStore.getDepartmentsByFacility(updateFacilityId);
            departments.value = [...facilityDepartments, ...additionalAuthorizedOrgs.value.departments];
        }
        catch {
            departments.value = [];
            throw new Error('Unable to get departments by facility');
        }
        finally {
            isDepartmentLoading.value = false;
        }
    }

    async function searchOrganizationForMatchingDepts(corpId?: number, regId?:number, divId?: number): Promise<void> {

        try {
            const orgMdl: IOrgWideDeptSearchIds = {
                corporationId: corpId,
                regionId: regId,
                divisionId: divId,
            }

            isOrgWideDepartmentLoading.value = true;

            orgWideDepartments.value = await organizationStore.organizationForMatchingDepts(orgMdl);
        }
        catch {
            orgWideDepartments.value = [];
            throw new Error('Unable to get organization wide departments');
        }
        finally {
            isOrgWideDepartmentLoading.value = false;
        }
    }

    function clearOrganizationLevels(clearOrgLevel: string): void {
        orgWideDepartments.value = [];

        departments.value = []

        if (clearOrgLevel === 'facility')
            return;

        facilities.value = [];

        if (clearOrgLevel === 'division')
            return;

        divisions.value = [];

        if (clearOrgLevel === 'region')
            return;

        regions.value = [];
    }

    function resetOrgIds(orgLevel: string): void {
        orgWideDeptName.value = undefined;
        departmentId.value = undefined;

        if (orgLevel === 'department' || !shouldShowFacility.value)
            return;

        facilityId.value = undefined;

        if (orgLevel === 'facility' || !shouldShowDivision.value)
            return;

        divisionId.value = undefined;

        if (orgLevel === 'division' || !shouldShowRegion.value)
            return;

        regionId.value = undefined;
    }

    return {
        getCorporations,
        corporations,
        corporationId,
        isCorporationLoading,
        shouldShowCorporation,

        getRegionByCorp,
        regions,
        regionId,
        isRegionLoading,
        isRegionDisabled,
        shouldShowRegion,

        getDivisionsByRegion,
        divisions,
        divisionId,
        isDivisionLoading,
        isDivisionDisabled,
        shouldShowDivision,

        getAllAvailableFacilities,
        getFacilitiesByDivision,
        facilities,
        facilityId,
        isFacilityLoading,
        isFacilityDisabled,
        shouldShowFacility,

        getDepartmentsByFacility,
        departments,
        departmentId,
        isDepartmentLoading,
        isDepartmentDisabled,
        shouldShowDepartment,

        orgWideDeptName,
        orgWideDepartments,
        isOrgWideDepartmentDisabled,
        isOrgWideDepartmentLoading,

        initializationComplete,
        manualInitialization
    }
}

export default useOrganizationHierarchy;
