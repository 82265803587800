<script setup lang="ts">
import IAssignmentPreferences from '@/models/courses/StudentAssignment/IAssignmentPreferences';
import IUpdateAssignmentPreferences from '@/models/courses/StudentAssignment/IUpdateAssignmentPreferences';
import useCourseAssignmentStore from '@/store/courseAssignment.store';
import useMainStore from '@/store/main.store';
import { toTypedSchema } from '@vee-validate/zod';
import InputSwitch from 'primevue/inputswitch';
import { useField, useForm } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import * as zod from 'zod';

// eslint-disable-next-line no-undef
const props = defineProps<{
    srcAssignmentPreference: IAssignmentPreferences
}>();

const mainStore = useMainStore();
const courseAssignmentStore = useCourseAssignmentStore();

const { handleSubmit, errors } = useForm({
    initialValues: {
        newHireAllYear: false
    },
    validationSchema: toTypedSchema(
        zod.object({
            newHireDays: zod.number().int().optional(),
            newHireAllYear: zod.boolean()
        })
    )
});

const { value: newHireDays } = useField<number>('newHireDays');
const { value: newHireAllYear } = useField<boolean>('newHireAllYear');
const createdByAdmin = ref<string>('');
const createOnDate = ref<Date>();
const modifiedByAdmin = ref<string>('');
const modifiedOnDate = ref<Date>();

let assignmentPreference = computed(() => props.srcAssignmentPreference);
const isLoading = computed<boolean>(() => mainStore.isBusy);

watch(assignmentPreference, () => {

    newHireAllYear.value = assignmentPreference.value.newHireAllYear ?? false;
    newHireDays.value = newHireAllYear.value ? 365 : (assignmentPreference.value.newHireDays ?? 0);
    
    createdByAdmin.value = '';
    createOnDate.value = new Date;
    modifiedByAdmin.value = '';
    modifiedOnDate.value = new Date;
});

const showNewHireDays = computed(() => !newHireAllYear.value);

onMounted(() => {
    newHireDays.value = assignmentPreference.value.newHireDays ?? 0;
    newHireAllYear.value = assignmentPreference.value.newHireAllYear ?? false;
})

const onSubmit = handleSubmit(async (values) => {
    const { newHireAllYear, newHireDays} = values;

    const updateMdl: IUpdateAssignmentPreferences = {
        courseAssignmentPreferenceId: assignmentPreference.value.courseAssignmentPreferenceId,
        newHireDays: newHireAllYear ? 365 : (newHireDays ?? 0),
        newHireAllYear, 
    }

    await courseAssignmentStore.updateAssignmentPreferences(updateMdl);
});

</script>

<template>
    <div>
        <form @submit="onSubmit">

            <div class="mt-3">
                <span> ({{ assignmentPreference.facilityId }}) {{ assignmentPreference.facilityName }} </span>
            </div>
            
            <div class="flex flex-column mt-5 w-full">
                <span>New Hire All Year</span>
                <InputSwitch v-model="newHireAllYear" class="mt-1"/>
                <small class="p-error"> {{ errors.newHireAllYear }}</small>
            </div>
            
            <div v-show="showNewHireDays">
                <div class="flex flex-column mt-5 w-full">
                    <span class="p-float-label w-full">
                        <InputNumber v-model="newHireDays" />
                        <label>New Hire Days</label>
                    </span>
                    <small class="p-error"> {{ errors.newHireDays }}</small>
                </div>
            </div>
  
            <div class="hidden flex-column my-3">

                <div class="flex flex-row mt-3">
                    <span class="mr-2">Created By: </span>
                    <span> {{ createdByAdmin }} </span>
                    <span class="mr-2">on</span>
                    <span> {{ createOnDate }} </span>
                </div>

                <div class="flex flex-row mt-3">
                    <span class="mr-2">Modified By: </span>
                    <span> {{ modifiedByAdmin }} </span>
                    <span class="mr-2">on</span>
                    <span> {{ modifiedOnDate }} </span>
                </div>

            </div>

            <div class="flex flex-row justify-content-end mb-4 mt-3">
                <Button 
                    label="Save" 
                    type="submit" 
                    icon="pi pi-save" 
                    iconPos="right" 
                    :loading="isLoading"
                />
            </div>
        </form>
    </div>    
</template>
