<script setup lang="ts">
import ISimpleCourse from '@/models/courses/Course/ISimpleCourse';
import INewHireCourseAssignment from '@/models/courses/StudentAssignment/NewHire/INewHireCourseAssignment';
import useCourseAssignmentStore from '@/store/courseAssignment.store';
import useMainStore from '@/store/main.store';
import { computed, onMounted, ref, watch } from 'vue';
import router from '@/router';

// eslint-disable-next-line no-undef
const props = defineProps<{
    srcNewHireAssignment?: INewHireCourseAssignment
}>();

const mainStore = useMainStore();
const courseAssignmentStore = useCourseAssignmentStore();

const showDeleteModal = ref<boolean>(false);

const facilityName = ref<string>('');
const departmentName = ref<string>('');
const createdByName = ref<string>('');
const disabledByName = ref<string>('');
const createdOnDate = ref<string>('');
const disabledOnDate = ref<string>('');
const assignByDepartment = ref<boolean>(false);
const course = ref<ISimpleCourse>();
const daysToTarget = ref<number>(0);
const daysToDrop = ref<number>(0);
const effectiveDateStart = ref<string>();
const effectiveDateEnd = ref<string>();
const isDisabled = ref<boolean>(false);

const newHireAssignment = computed(() => props.srcNewHireAssignment);
const isLoading = computed<boolean>(() => mainStore.isBusy);

onMounted(() => {
   initializeValues(); 
});

watch(newHireAssignment, (() => {
    initializeValues();
}));

function initializeValues() {
    facilityName.value = newHireAssignment.value?.facility?.name ?? '';
    departmentName.value = newHireAssignment.value?.departmentName ?? '';
    assignByDepartment.value = newHireAssignment.value?.assignByDepartment ?? false;
    course.value = newHireAssignment.value?.course;
    daysToTarget.value = newHireAssignment.value?.daysToTarget ?? 0;
    daysToDrop.value = newHireAssignment.value?.daysToDrop ?? 0;
    effectiveDateStart.value = !newHireAssignment.value?.effectiveDateStart ? '' : new Date(newHireAssignment.value.effectiveDateStart).toLocaleString();
    effectiveDateEnd.value = !newHireAssignment.value?.effectiveDateEnd ? '' : new Date(newHireAssignment.value.effectiveDateEnd).toLocaleString();
    isDisabled.value = newHireAssignment.value?.isDisabled ?? false;
    createdByName.value = !newHireAssignment.value?.createdByAdmin ?  '' : `${ newHireAssignment.value.createdByAdmin.firstName } ${ newHireAssignment.value.createdByAdmin.lastName }`;
    disabledByName.value = !newHireAssignment.value?.disabledByAdmin ?  '' : `${ newHireAssignment.value.disabledByAdmin.firstName } ${ newHireAssignment.value.disabledByAdmin.lastName }`;
    createdOnDate.value = (!newHireAssignment.value?.createdOn) ? '' : new Date(newHireAssignment.value.createdOn).toLocaleDateString();
    disabledOnDate.value = !newHireAssignment.value?.disabledDate ? '' : new Date(newHireAssignment.value.disabledDate).toLocaleDateString();
}
    
async function deleteAssignment() {
    const newHireCourseAssignmentId = newHireAssignment.value?.newHireCourseAssignmentId;

    if (!newHireCourseAssignmentId)
        return mainStore.setErrorMsg('New Hire Assignment Id not found.')

    try {
        await courseAssignmentStore.deleteNewHireAssignment(newHireCourseAssignmentId);
        showDeleteModal.value = false;
        setTimeout(() => router.push('/automated-course-assignment/new-hires'), 2500);
    } catch (e) {
        console.error('Error deleting new hire assignment', e);
    }
}

</script>

<template>
    <div>

        <div v-show="!assignByDepartment">
            <div class="flex flex-row pt-3">
                <span class="pr-2">Facility: </span>
                <span> {{ facilityName }} </span>
            </div>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Assign By Department</span>
            <span> {{ assignByDepartment ? "Yes" : "No" }} </span>
        </div>
        
        <div v-show="assignByDepartment">
            <div class="flex flex-row pt-3" >
                <span class="pr-2">Department: </span>
                <span> {{ departmentName }} </span>
            </div>
        </div>
                
        <div class="flex flex-row pt-3">
            <span class="pr-2">Course: </span>
            <span> {{ course?.name }} </span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Days to Target: </span>
            <span> {{ daysToTarget }} Days</span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Days to Drop: </span>
            <span> {{ daysToDrop }} Days</span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Effective Start Date</span>
            <span> {{ effectiveDateStart }} </span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Effective End Date: </span>
            <span> {{ effectiveDateEnd }} </span>
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Disabled: </span>
            <span> {{ isDisabled ? "Yes" : "No" }} </span>
        </div>
        
        <div class="flex flex-row justify-content-between md:justify-content-end pb-4 pt-6">
            <Button 
                label="Delete" 
                icon="pi pi-check" 
                iconPos="right"
                severity="danger" 
                @click="showDeleteModal = true"
                :loading="isLoading" 
            />
        </div>

        <div class="flex flex-row pt-3">
            <span class="pr-2">Created By: </span>
            <span> {{ createdByName }} </span>
            <span class="px-2"> on </span>
            <span> {{ createdOnDate }} </span>
        </div>

        <div v-show="false">
            <div class="flex flex-row pt-3">
                <span class="pr-2">Disabled By: </span>
                <span> {{  }} </span>
                <span class="pr-x">on</span>
                <span> {{  }} </span>
            </div>
        </div>

        <div v-show="false">    
            <div class="flex flex-row pt-3">
                <span class="pr-2">Modified by: </span>
                <span> {{ disabledByName }} </span>
                <span class="pr-x">on</span>
                <span> {{ disabledOnDate }} </span>
            </div>
        </div>

        <Dialog v-model:visible="showDeleteModal" modal header="Are you sure?"> 
            <div class="pt-3">
                <Button 
                    label="No" 
                    icon="pi pi-times" 
                    class="mr-3" 
                    text 
                    autofocus
                    @click="showDeleteModal = false" 
                />
                <Button 
                    label="Yes" 
                    icon="pi pi-check" 
                    @click="deleteAssignment" 
                />
            </div>
        </Dialog>


    </div>
</template>@/models/courses/Course/ISimpleCourse
