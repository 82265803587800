<script setup lang="ts">
import useCourseStore from '@/store/course.store';
import useMainStore from '@/store/main.store';
import ICourseOther from '@/models/courses/Course/ICourseOther';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import {computed, ref} from 'vue';

const courseStore = useCourseStore();
const mainStore = useMainStore();

courseStore.$onAction(({ after, name }) => {
    after(() => {
        if (name === 'getCourse'){
            initializeValues();
        }
    })
});

const { handleSubmit, resetForm } = useForm({
    validationSchema: toTypedSchema(
        zod.object({
            userDefinedField1: zod.string().optional().nullable(),
            userDefinedField2: zod.string().optional().nullable(),
            userDefinedField3: zod.string().optional().nullable(),
            userDefinedField4: zod.string().optional().nullable(),
            approvalNumber: zod.string().optional().nullable(),
            userDefinedField6: zod.string().optional().nullable(),
            userDefinedField7: zod.string().optional().nullable(),
            userDefinedField8: zod.string().optional().nullable(),
        })
    )
});


const displayingUserDefinedField = ref<number>(0);

const { value: userDefinedField1 } = useField<string>('userDefinedField1');
const { value: userDefinedField2 } = useField<string>('userDefinedField2');
const { value: userDefinedField3 } = useField<string>('userDefinedField3');
const { value: userDefinedField4 } = useField<string>('userDefinedField4');
const { value: approvalNumber } = useField<string>('approvalNumber');
const { value: userDefinedField6 } = useField<string>('userDefinedField6');
const { value: userDefinedField7 } = useField<string>('userDefinedField7');
const { value: userDefinedField8 } = useField<string>('userDefinedField8');

const isLoading = computed<boolean>(() => mainStore.isBusy);

const initializeValues = () => {
    const course = courseStore.getActiveCourse;

    if (!course)
        return mainStore.setErrorMsg('No active course found');

    resetForm({
        values: {
            userDefinedField1: course.userDefinedField1,
            userDefinedField2: course.userDefinedField2,
            userDefinedField3: course.userDefinedField3,
            userDefinedField4: course.userDefinedField4,
            approvalNumber: course.approvalNumber,
            userDefinedField6: course.userDefinedField6,
            userDefinedField7: course.userDefinedField7,
            userDefinedField8: course.userDefinedField8,
        }
    })

    if (userDefinedField6.value) { displayingUserDefinedField.value = 1; }
    if (userDefinedField7.value) { displayingUserDefinedField.value = 2; } 
    if (userDefinedField8.value) { displayingUserDefinedField.value = 3; }
};

const onSubmit = handleSubmit(async (values) => {
    const courseId = courseStore.getActiveCourseId;

    if (!courseId)
        return mainStore.setErrorMsg('No active course found');

    const {
        userDefinedField1,
        userDefinedField2,
        userDefinedField3,
        userDefinedField4,
        approvalNumber,
        userDefinedField6,
        userDefinedField7,
        userDefinedField8
    } = values;

    const updateCourse: ICourseOther = {
        courseId,
        userDefinedField1: userDefinedField1,
        userDefinedField2: userDefinedField2,
        userDefinedField3: userDefinedField3,
        userDefinedField4: userDefinedField4,
        approvalNumber: approvalNumber,
        userDefinedField6: userDefinedField6,
        userDefinedField7: userDefinedField7,
        userDefinedField8: userDefinedField8,
    };

    await courseStore.updateCourseOther(updateCourse);
});

function addField() {
    displayingUserDefinedField.value++;
}

</script>

<template>
    <div class="border-round-lg" style="background-color: var(--hca-form-bg);">
        <form @submit="onSubmit" class="p-3" >
        
            <div class="flex flex-column">
                
                <div class="flex mt-5">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField1" class="w-full"/>
                        <label>Custom Field #1</label>
                    </span>
                </div>

                <div class="flex mt-5">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField2" class="w-full"/>
                        <label>CE Broker Lesson # OR Clock Hours (NAB)</label>
                    </span>
                </div>

                <div class="flex mt-5">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField3" class="w-full"/>
                        <label>Approval # (NAB) OR Exp Date (ANCC)</label>
                    </span>
                </div>
                
                <div class="flex mt-5">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField4" class="w-full"/>
                        <label>Tags or Expiration Data (NAB)</label>
                    </span>
                </div>

                
                <div class="flex mt-5">
                    <span class="p-float-label w-full">
                        <Textarea v-model="approvalNumber" class="w-full"/>
                        <label>Approval Number</label>
                    </span>
                </div>
                

                <div class="py-3" v-show="displayingUserDefinedField < 3">
                    <Button 
                        label="Add Field" 
                        icon="pi pi-plus" 
                        outlined
                        @click="addField" 
                    />
                </div>

                <div class="mt-5" v-show="displayingUserDefinedField >= 1">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField6" class="w-full"/>
                        <label>Custom Field #6</label>
                    </span>
                </div>
                
                <div class="mt-5" v-show="displayingUserDefinedField >= 2">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField7" class="w-full"/>
                        <label>Custom Field #7</label>
                    </span>
                </div>
                
                <div class="py-5" v-show="displayingUserDefinedField >= 3">
                    <span class="p-float-label w-full">
                        <Textarea v-model="userDefinedField8" class="w-full"/>
                        <label>Custom Field #8</label>
                    </span>
                </div>

            </div>

            <div class="flex flex-row justify-content-end mt-3">
                <Button
                    label="Save" 
                    type="submit" 
                    :loading="isLoading"
                />
            </div>
                
        </form>
    </div>
</template>
